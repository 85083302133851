/* eslint-disable */
import { Link } from "react-router-dom";
import CategoryTab2 from "../components/elements/CategoryTab2";
import BlogSlider from "../components/sliders/Blog";
import SchoolSearch from "../components/school_search/school_search";

import { useRef, useState } from "react";
import FloatingPopUp from "../components/elements/PopUp";

export const EmployerHome = () => {
  const [billingType, setBillingType] = useState("monthly");
  const [keyword, setKeyword] = useState("");
  const [state, setState] = useState("CA");
  const partnerSignupRef = useRef(null);
  const handleKeywordChange = (event) => {
    setKeyword(event.target.value);
  };
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    businessName: "",
    message: "",
  });
  const [formErrors, setErrors] = useState({});
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const handleStateChange = (event) => {
    setState(event.target.value);
  };

  const handleCheckboxChange = (e) => {
    setCheckboxChecked(e.target.checked);
  };

  const handleSignupButton = () => {
    console.log("signup");
    setTimeout(() => {
      partnerSignupRef.current.scrollIntoView({ behavior: "smooth" });
    }, 100);
    console.log("partnerSignupRef", partnerSignupRef);
  };

  const handleInputChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
    setSuccessMessage("");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const errors = validateForm(formData);
    if (Object.keys(errors).length !== 0) {
      setErrors(errors);
      return;
    }

    const data = {
      name: formData.name,
      email: formData.email,
      phone: formData.phone,
      businessName: formData.businessName,
      message: formData.message,
      contactFormLocation: "Employer Contact Form",
    };
    await dispatch(saveContactForm(data)).then((x) => {
      setSuccessMessage(
        "Submitted successfully, Our Representative will get back to you soon"
      );
      // Reset the form and errors
      setFormData({
        name: "",
        email: "",
        phone: "",
        businessName: "",
        message: "",
      });
      setErrors({});
    });
  };

  function validateForm(formData) {
    const errors = {};

    if (!formData.name.trim()) {
      errors.name = "Name is required";
    } else if (!/^[a-zA-Z ]+$/.test(formData.name)) {
      errors.name = "Name should only contain alphabets";
    }

    if (!formData.phone.trim()) {
      errors.phone = "Phone number is required";
    } else if (!/^\d+$/.test(formData.phone)) {
      errors.phone = "Invalid phone number format";
    }

    if (!formData.email.trim()) {
      errors.email = "Email is required";
    } else if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(formData.email)) {
      errors.email = "Invalid email format";
    }

    if (!formData.message.trim()) {
      errors.message = "Message is required";
    }

    if (!formData.businessName.trim()) {
      errors.businessName = "businessName Name is required";
    }

    if (!checkboxChecked) {
      errors.check_box = "Please agree to the terms and policy";
    }

    return errors;
  }

  return (
    <>
      <div>
        <section className="section-box pb-40 pt-30 hero-section-employer">
          <div className="banner-hero hero-1 banner-homepage5">
            <div className="banner-inner">
              <div className="row">
                <div className="col-lg-12">
                  <div className="block-banner block-banner-student">
                    {/* heading-banner wow animate__animated animate__fadeInUp */}
                    <h1 className="text-5xl leading-snug font-extrabold">
                      AdultEd Pro: Brand, Source, Engage and Hire Talent from
                      Adult Education Centers, all in one place.
                    </h1>
                  </div>
                </div>
              </div>

              {/* <div className="box-search-2">
                <div className="block-banner ml-40">
                  <h4 className="text-2xl font-bold">Search for School</h4>
                  <SchoolSearch />
                </div>
              </div> */}
            </div>
          </div>
        </section>

        <section className="section-box overflow-visible mt-50 mb-50">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                <div className="text-center">
                  <h1 className="text-6xl leading-snug font-extrabold text-[#3C65F5]">
                    <span className="count">Brand</span>
                  </h1>
                  <h5 className="text-xl font-bold">
                    Build your employer profile
                  </h5>
                  <p className="font-sm color-text-paragraph mt-10">
                    with powerful tools and capabilities. Add images and videos
                    to showcase your culture and stand out from the rest of the
                    crowd, feature alumni and ambassadors and speak the language
                    of your candidates..
                  </p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                <div className="text-center">
                  <h1 className="text-6xl leading-snug font-extrabold text-[#3C65F5]">
                    <span className="count">Source</span>
                  </h1>
                  <h5 className="text-xl font-bold">Browse student profiles</h5>
                  <p className="font-sm color-text-paragraph mt-10">
                    with powerful recommendations and screen candidates with AI
                    designed to help you more efficiently find the best fit for
                    your company.
                  </p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                <div className="text-center">
                  <h1 className="text-6xl leading-snug font-extrabold text-[#3C65F5]">
                    <span className="count">Engage</span>
                  </h1>
                  <h5 className="text-xl font-bold">
                    Two way direct communication
                  </h5>
                  <p className="font-sm color-text-paragraph mt-10">
                    with candidates powered by AI. Upload screening requirements
                    or let us do that for you using AI, and give candidates that
                    warm introduction that leads to a hire down the road.
                  </p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                <div className="text-center">
                  <h1 className="text-6xl leading-snug font-extrabold text-[#3C65F5]">
                    <span className="count">Hire</span>
                  </h1>
                  <h5 className="text-xl font-bold">Recruit and Report</h5>
                  <p className="font-sm color-text-paragraph mt-10">
                    Setup interviews with candidates, plan career fairs at
                    schools, host lunches and provide feedback. With AdultEd
                    Pro, you're always on campus in one form or another!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section-box mt-50 mb-30 bg-border-3 py-10">
          <div className="container mt-3 flex flex-col justify-center items-center ">
            <div className="container mb-4 flex flex-col justify-center items-center text-center">
              <h2 className="section-title mb-10 wow animate__animated animate__fadeInUp text-3xl font-bold">
                Adult Education Students are primarily made up of so-called
                "hidden workers"
              </h2>
              <p className=" mt-2 font-lg pt-3 color-text-paragraph w-full mx-auto wow animate__animated animate__fadeInUp  color-text-paragraph-2 leading-6">
                "Hidden workers" which is a population generally overlooked by
                employers. Studies have shown that these workers end up
                outperforming their traditional peers on 5 major employment
                measures (Attitude and work ethic, Productivity, Quality of
                work, Employee engagement, Attendance and Innovation).
              </p>
            </div>
            <div className="w-full flex justify-center items-center">
              <div className="w-4/5 ">
                <img
                  src="../assets/imgs/page/employer/High_Performance_Hidden_Workers.jpg"
                  alt=""
                />
              </div>
            </div>
          </div>
        </section>

        <section className="section-box mt-70 mb-40">
          <div className="container">
            <div className="text-start">
              <h2 className="text-4xl font-bold">Features Plan</h2>
              <p className="font-lg mt-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                A plan for all types of organization to help you get up and
                running quickly.
              </p>
            </div>
            <div className="mt-70 row">
              <div className="col text-center">
                <div className="switch-wrapper">
                  <input
                    id="monthly"
                    type="radio"
                    name="switch"
                    checked={billingType === "monthly"}
                    onChange={() => setBillingType("monthly")}
                  />
                  <input
                    id="yearly"
                    type="radio"
                    name="switch"
                    checked={billingType === "yearly"}
                    onChange={() => setBillingType("yearly")}
                  />
                  <label htmlFor="monthly">Monthly</label>
                  <label htmlFor="yearly">Yearly</label>
                  <span className="highlighter"></span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col  text-center">
                <a href="#candidate-form">
                  {billingType === "monthly" ? (
                    <img
                      style={{ margin: "0 auto" }}
                      src="assets/imgs/monthly-pricing.jpg"
                      alt="Monthly Pricing"
                    />
                  ) : (
                    <img
                      style={{ margin: "0 auto" }}
                      src="assets/imgs/annual-pricing.jpg"
                      alt="Annual Pricing"
                    />
                  )}
                </a>
              </div>
            </div>
          </div>
        </section>

        <section id="candidate-form" className="container section-box mt-80 ">
          <div ref={partnerSignupRef} className="container w-3/5">
            <div className="row">
              <div className="w-full mb-40">
                <span className="font-md color-brand-2 mt-20 d-inline-block">
                  Contact us
                </span>
                <h2 className="mt-5 mb-10 text-3xl font-bold">
                  Employer Signup
                </h2>
                <p className="font-md color-text-paragraph-2">
                  Sign up with{" "}
                  <Link to="/" className="text-blue-600">
                    adultedpro.com
                  </Link>{" "}
                  to start posting your openings and see what a hidden worker
                  can do for you!.
                </p>
                <form
                  className="contact-form-style mt-30"
                  id="contact-form"
                  action="#"
                  method="post"
                >
                  <div
                    className="row wow animate__animated animate__fadeInUp"
                    data-wow-delay=".1s"
                  >
                    <div className="col-lg-6 col-md-6">
                      <div className="input-style mb-20">
                        <input
                          className="font-sm color-text-paragraph-2"
                          name="name"
                          placeholder="Name"
                          type="text"
                          value={formData.name}
                          onChange={handleInputChange}
                          required
                        />
                        {formErrors.name && (
                          <div className="text-danger">{formErrors.name}</div>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="input-style mb-20">
                        <input
                          className="font-sm color-text-paragraph-2"
                          name="businessName"
                          placeholder="Business Name"
                          type="text"
                          value={formData.businessName}
                          onChange={handleInputChange}
                          required
                        />
                        {formErrors.businessName && (
                          <div className="text-danger">
                            {formErrors.businessName}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="input-style mb-20">
                        <input
                          className="font-sm color-text-paragraph-2"
                          name="email"
                          placeholder="Your email"
                          type="email"
                          value={formData.email}
                          onChange={handleInputChange}
                          required
                        />
                        {formErrors.email && (
                          <div className="text-danger">{formErrors.email}</div>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="input-style mb-20">
                        <input
                          className="font-sm color-text-paragraph-2"
                          name="phone"
                          placeholder="Phone number"
                          type="tel"
                          value={formData.phone}
                          onChange={handleInputChange}
                          required
                        />
                        {formErrors.phone && (
                          <div className="text-danger">{formErrors.phone}</div>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                      <div className="textarea-style mb-30">
                        <textarea
                          className="font-sm color-text-paragraph-2 px-2 py-2"
                          name="message"
                          placeholder="Type your message here"
                          value={formData.message}
                          onChange={handleInputChange}
                          required
                        />
                        {formErrors.message && (
                          <div className="text-danger">
                            {formErrors.message}
                          </div>
                        )}
                      </div>
                      <button
                        className="submit btn btn-send-message bg-[#05264e]"
                        type="submit"
                        onClick={handleSubmit}
                      >
                        Send message
                      </button>
                      {formErrors.check_box && (
                        <div className="text-danger">
                          {formErrors.check_box}
                        </div>
                      )}
                      <label className="ml-20">
                        <input
                          className="float-start mr-5 mt-6"
                          type="checkbox"
                          checked={checkboxChecked}
                          onChange={handleCheckboxChange}
                          required
                        />{" "}
                        By clicking send message button, you agree our{" "}
                        <a href="/legal/terms-of-service">Terms of Service</a>.
                      </label>
                      {successMessage && (
                        <div className="text-success">{successMessage}</div>
                      )}
                      <div className="ml-20">
                        <div className="float-start mr-5 mt-6">
                          Already have an account? &nbsp;
                          <a
                            className="text-brand-2 font-weight-bold"
                            href="https://app.adultedpro.com/auth/employer/signin"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Sign in
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
                <p className="form-messege" />
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
