import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { db } from '../../services/firebase';
import { collection, doc, getDoc, getDocs, query, setDoc, where } from "firebase/firestore";
import { v4 as uuidv4 } from 'uuid';

const setPartnerDoc = (partnerDoc) => {
  let partner = undefined;
  if (partnerDoc.exists()) {
    partner = {
      id: partnerDoc.data().id,
      addressLine1: partnerDoc.data().addressLine1,
      addressLine2: partnerDoc.data().addressLine2,
      bannerColor: partnerDoc.data().bannerColor,
      bannerText: partnerDoc.data().bannerText,
      carouselImages: partnerDoc.data().carouselImages,
      city: partnerDoc.data().city,
      events: partnerDoc.data().events,
      footerLogo: partnerDoc.data().footerLogo,
      jobsView: partnerDoc.data().jobsView,
      logoText: partnerDoc.data().logoText,
      logoUrl: partnerDoc.data().logoUrl,
      name: partnerDoc.data().name,
      profilePic: partnerDoc.data().profilePic,
      showProfile: partnerDoc.data().showProfile,
      state: partnerDoc.data().state,
      staticImage: partnerDoc.data().staticImage,
      studentUserIds: partnerDoc.data().studentUserIds,
      tagLine: partnerDoc.data().tagLine,
      textColor: partnerDoc.data().textColor,
    };
  }
  return partner;
}

export const fetchPartners = createAsyncThunk('partners/fetchPartners', async () => {
    const querySnapshot = await getDocs(collection(db, "partners"));
    return querySnapshot.docs.map(doc => {
      return setPartnerDoc(doc);
  });

});


export const fetchPartner = createAsyncThunk('partners/fetchPartner', async (partnerId) => {
      
  const partnerDoc = await getDoc(doc(db, "partners", partnerId));

  return setPartnerDoc(partnerDoc);

});

export const fetchPartnerByEmail = createAsyncThunk(
  "partners/fetchPartnerByEmail",
  async (email) => {
    const q = query(collection(db, "partners"), where("email", "==", email));

    const querySnapshot = await getDocs(q);
    let partners = querySnapshot.docs.map((doc) => setPartnerDoc(doc));
    return partners[0];
  }
);
export const fetchPartnerByName = createAsyncThunk(
  "partners/fetchPartnerByName",
  async (name) => {
    const q = query(collection(db, "partners"), where("name", "==", name.trim()));

    const querySnapshot = await getDocs(q);
    let partners = querySnapshot.docs.map((doc) => setPartnerDoc(doc));
    return partners[0];
  }
);


export const setPartner = createAsyncThunk("users/setPartner", async (partnerData) => {
  if (!partnerData.id) {
    partnerData.id = uuidv4();
  }
  const partnersRef = collection(db, "partners");

  return await setDoc(doc(partnersRef, partnerData.id), partnerData, {
    merge: true,
  }).then((u) => {
    return {
      id: partnerData.id,
      email: partnerData.email,
    };
  });
});

const initialState = {
  partner: undefined,
  partners: [],
  isLoading: false,
  error: null,
};

const partnersSlice = createSlice({
  name: "partners",
  initialState,
  reducers: {
    resetState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPartners.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchPartners.fulfilled, (state, action) => {
        state.isLoading = false;
        state.partners = action.payload;
      })
      .addCase(fetchPartners.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(fetchPartner.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchPartner.fulfilled, (state, action) => {
        state.isLoading = false;
        state.partner = action.payload;
      })
      .addCase(fetchPartner.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(fetchPartnerByEmail.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchPartnerByEmail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.partner = action.payload;
      })
      .addCase(fetchPartnerByEmail.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(fetchPartnerByName.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchPartnerByName.fulfilled, (state, action) => {
        state.isLoading = false;
        state.partner = action.payload;
      })
      .addCase(fetchPartnerByName.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(setPartner.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(setPartner.fulfilled, (state, action) => {
        state.isLoading = false;
        state.partner = action.payload;
      })
      .addCase(setPartner.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(partnersActions.resetState, (state) => initialState);
  },
});

export default partnersSlice.reducer;
export const { actions: partnersActions } = partnersSlice;



