import {BsFillCalendar2CheckFill, BsFillCloudArrowUpFill} from 'react-icons/bs';
import {FaBlackTie} from 'react-icons/fa';

export const default_background = (user_name)=>{


    return `${user_name} is a Glendale Community College student who is looking for help trying to find a career in bookkeeping or accounting. He is currently taking English classes at Burbank Adult School. He has some background in Accounting by working briefly in Accounts Payable. He is actively applying to jobs and needs help in improving his interviewing and presentation skills. He has good subject knowledge matter and dreams of pursing Accounting further, transferring to a Cal-State and getting an Accounting degree.`
}
export const default_job_sought= (user_name)=>{


    return `${user_name} is looking for any job that will improve his Accounting and Finance skills. He is open to AP, AR, Bookkeping or Clerk positions. English is his second language and he is actively working towards improving it by taking classes at BAC.`
}


export const default_events= ()=>{

    return [
        {title : 'is Interviewing', name:"Wajid Ali Khan", icon: <BsFillCalendar2CheckFill />  },
        {title : 'was Hired', name: "Sadia Asif", icon: <FaBlackTie /> },
        {title : 'is Applying', name : "Haseeb Saeed", icon: <BsFillCloudArrowUpFill />  },
    ]


}

export const super_admin_id = '15d74d8b-7ed3-4dfd-8e13-466b235abad1'
export const default_tasks = () => {
    return (
      <>
        10:40 AM, Fri., Sept. 10, 2021 <br />
        New job posting - Acme Inc. <br />
        Check the job description and requirements to make sure everything is correct. <br />
        10:40 AM, Fri., Sept. 10, 2021 <br />
        New job posting - Ace Industries <br />
        Check the job description and requirements to make sure everything is correct. <br />
        <br />
      </>
    );
  };

