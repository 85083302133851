import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { authenticate } from "../../services/firebase";
import {
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  FacebookAuthProvider,
  signInWithPopup,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut as logOut,
} from "firebase/auth";

export const signUp = createAsyncThunk(
  "auth/signUp",
  async (userCredentials) => {
    const result = await createUserWithEmailAndPassword(
      authenticate,
      userCredentials.email,
      userCredentials.password
    );
    return {
      id: result.user.uid,
      email: result.user.email,
      displayName: result.user.displayName,
      photoUrl: result.user.photoURL,
    };
  }
);

export const signIn = createAsyncThunk(
  "auth/signIn",
  async (userCredentials) => {
    const result = await signInWithEmailAndPassword(
      authenticate,
      userCredentials.email,
      userCredentials.password
    );
    console.log('signin result', result );
    return {
      id: result.user.uid,
      email: result.user.email,
      displayName: result.user.displayName,
      photoUrl: result.user.photoURL,
    };
  }
);

export const signInWithGoogle = createAsyncThunk(
  "auth/signInWithGoogle",
  async () => {
    const googlePovider = new GoogleAuthProvider();
    const result = await signInWithPopup(authenticate, googlePovider);

    return {
      id: result.user.id,
      email: result.user.email,
      displayName: result.user.displayName,
      photoUrl: result.user.photoUrl,
    };
  }
);

export const signInWithFacebook = createAsyncThunk(
  "auth/signInWithFacebook",
  async () => {
    const googlePovider = new FacebookAuthProvider();
    const result = await signInWithPopup(authenticate, googlePovider);

    return {
      id: result.user.id,
      email: result.user.email,
      displayName: result.user.displayName,
      photoUrl: result.user.photoUrl,
    };
  }
);

export const signOut = createAsyncThunk("auth/signOut", async () => {
  localStorage.clear();
  logOut(authenticate);
});

export const forgotPassword = createAsyncThunk(
  "auth/forgotPassword",
  async (email) => {
    sendPasswordResetEmail(authenticate, email);
  }
);
const initialState = {
  auth: null,
  isLoading: false,
  error: null,
};
const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    resetState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(signUp.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(signUp.fulfilled, (state, action) => {
        state.isLoading = false;
        state.auth = action.payload;
        localStorage.setItem("auth", JSON.stringify(action.payload));
      })
      .addCase(signUp.rejected, (state, action) => {
        state.isLoading = false;
        state.error = {
          message: action.error.message,
          code: action.error.code,
        };
      })
      .addCase(signInWithGoogle.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(signInWithGoogle.fulfilled, (state, action) => {
        state.isLoading = false;
        state.auth = action.payload;
        localStorage.setItem("auth", JSON.stringify(action.payload));
      })
      .addCase(signInWithGoogle.rejected, (state, action) => {
        state.isLoading = false;
        state.error = {
          message: action.error.message,
          code: action.error.code,
        };
      })
      .addCase(signInWithFacebook.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(signInWithFacebook.fulfilled, (state, action) => {
        state.isLoading = false;
        state.auth = action.payload;
        localStorage.setItem("auth", JSON.stringify(action.payload));
      })
      .addCase(signInWithFacebook.rejected, (state, action) => {
        state.isLoading = false;
        state.error = {
          message: action.error.message,
          code: action.error.code,
        };
      })
      .addCase(signIn.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(signIn.fulfilled, (state, action) => {
        state.isLoading = false;
        state.auth = action.payload;
        localStorage.setItem("auth", JSON.stringify(action.payload));
      })
      .addCase(signIn.rejected, (state, action) => {
        state.isLoading = false;
        state.error = {
          message: action.error.message,
          code: action.error.code,
        };
      })
      .addCase(signOut.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(signOut.fulfilled, (state, action) => {
        state.isLoading = false;
        state.auth = null;
        localStorage.clear("auth");
      })
      .addCase(signOut.rejected, (state, action) => {
        state.isLoading = false;
        state.error = {
          message: action.error.message,
          code: action.error.code,
        };
        localStorage.clear("auth");
      })
      .addCase(forgotPassword.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        state.isLoading = false;
        state.auth = null;
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        state.isLoading = false;
        state.error = {
          message: action.error.message,
          code: action.error.code,
        };
      })
      .addCase(authActions.resetState, (state) => initialState);
  },
});

export default authSlice.reducer;
export const { actions: authActions } = authSlice;
