import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";

SwiperCore.use([Navigation]);

const BlogSlider = () => {
  return (
    <>
      <div className="swiper-container swiper-group-3 swiper">
        <Swiper
          slidesPerView={1}
          spaceBetween={30}
          loop={true}
          navigation={{
            prevEl: ".swiper-button-prev",
            nextEl: ".swiper-button-next",
          }}
          className="swiper-wrapper pb-70 pt-5"
        >
          {/* <SwiperSlide>
            {/* <div className="swiper-slide">
                <div className="card-grid-3 hover-up wow animate__animated animate__fadeIn">
                    <div className="text-center card-grid-3-image">
                        <Link to="#">
                            <figure>
                                <img alt="jobBox" src="/assets/imgs/page/homepage1/img-news1.png" />
                            </figure>
                        </Link>
                    </div>
                    <div className="card-block-info">
                        <div className="tags mb-15">
                            <Link to="/blog-grid" className="btn btn-tag">
                                News
                            </Link>
                        </div>
                        <h5>
                            <Link to="/blog-grid">
                                21 Job Interview Tips: How To Make a Great Impression
                            </Link>
                        </h5>
                        <p className="mt-10 color-text-paragraph font-sm">Our mission is to create the world's most sustainable healthcare company by creating high-quality healthcare products in iconic, sustainable packaging.</p>
                        <div className="card-2-bottom mt-20">
                            <div className="row">
                                <div className="col-lg-6 col-6">
                                    <div className="d-flex">
                                        <img className="img-rounded" src="/assets/imgs/page/homepage1/user1.png" alt="jobBox" />
                                        <div className="info-right-img">
                                            <span className="font-sm font-bold color-brand-1 op-70">Sarah Harding</span>
                                            <br />
                                            <span className="font-xs color-text-paragraph-2">06 September</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 text-end col-6 pt-15">
                                    <span className="color-text-paragraph-2 font-xs">8 mins to read</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </SwiperSlide>  */}
          <SwiperSlide className="">
            <div className="swiper-slide">
              <div
                className="card-grid-2 hover-up wow animate__animated animate__fadeIn"
                style={{
                  width: 600,
                }}
              >
                <div className="text-center card-grid-3-image">
                  {/* <Link to="#">
                            <figure>
                                <img alt="jobBox" src="/assets/imgs/page/homepage1/img-news2.png" />
                            </figure>
                        </Link> */}
                  <video width="600" height="350" controls autoPlay>
                    <source
                      src="/assets/videos/job-tips.mp4"
                      type="video/mp4"
                    />
                  </video>
                </div>
                <div className="card-block-info">
                  <div className="tags mb-15">
                    <Link to="/blog-grid" className="btn btn-tag">
                      Events
                    </Link>
                  </div>
                  <h5>
                    <Link to="/">
                      Top Ten Job Tips/ Los 10 Mejores Consejos Para Entrevistas
                    </Link>
                  </h5>
                  {/* <p className="mt-10 color-text-paragraph font-sm">Our mission is to create the world's most sustainable healthcare company by creating high-quality healthcare products in iconic, sustainable packaging.</p>
                        <div className="card-2-bottom mt-20">
                            <div className="row">
                                <div className="col-lg-6 col-6">
                                    <div className="d-flex">
                                        <img className="img-rounded" src="/assets/imgs/page/homepage1/user2.png" alt="jobBox" />
                                        <div className="info-right-img">
                                            <span className="font-sm font-bold color-brand-1 op-70">Steven Jobs</span>
                                            <br />
                                            <span className="font-xs color-text-paragraph-2">06 September</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 text-end col-6 pt-15">
                                    <span className="color-text-paragraph-2 font-xs">6 mins to read</span>
                                </div>
                            </div>
                        </div> */}
                </div>
              </div>
            </div>
          </SwiperSlide>
          {/* <SwiperSlide>
            <div className="swiper-slide">
                <div className="card-grid-3 hover-up wow animate__animated animate__fadeIn">
                    <div className="text-center card-grid-3-image">
                        <Link to="#">
                            <figure>
                                <img alt="jobBox" src="/assets/imgs/page/homepage1/img-news3.png" />
                            </figure>
                        </Link>
                    </div>
                    <div className="card-block-info">
                        <div className="tags mb-15">
                            <Link to="/blog-grid" className="btn btn-tag">
                                News
                            </Link>
                        </div>
                        <h5>
                            <Link to="/blog-grid">
                                Interview Question: Why Dont You Have a Degree?
                            </Link>
                        </h5>
                        <p className="mt-10 color-text-paragraph font-sm">Learn how to respond if an interviewer asks you why you dont have a degree, and read example answers that can help you craft</p>
                        <div className="card-2-bottom mt-20">
                            <div className="row">
                                <div className="col-lg-6 col-6">
                                    <div className="d-flex">
                                        <img className="img-rounded" src="/assets/imgs/page/homepage1/user3.png" alt="jobBox" />
                                        <div className="info-right-img">
                                            <span className="font-sm font-bold color-brand-1 op-70">Wiliam Kend</span>
                                            <br />
                                            <span className="font-xs color-text-paragraph-2">06 September</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 text-end col-6 pt-15">
                                    <span className="color-text-paragraph-2 font-xs">9 mins to read</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </SwiperSlide> */}
        </Swiper>
      </div>
      <div className="swiper-button-next" />
      <div className="swiper-button-prev" />
    </>
  );
};

export default BlogSlider;
