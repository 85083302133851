/* eslint-disable */
import { Link } from "react-router-dom";
import CategoryTab2 from "../components/elements/CategoryTab2";
import BlogSlider from "../components/sliders/Blog";
import SchoolSearch from "../components/school_search/school_search";
import { collection, doc, setDoc } from "firebase/firestore";
import { v4 as uuidv4 } from "uuid";
import { useState } from "react";
import FloatingPopUp from "../components/elements/PopUp";
import { db } from "../services/firebase";
import { PatternFormat } from "react-number-format";

export const StudentHome = () => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [school, setSchool] = useState("");
  const [emailError, setEmailError] = useState("");
  const [nameError, setNameError] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    let isValid = true;

    if (!email) {
      setEmailError("Please enter your email");
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError("Please enter a valid email");
      isValid = false;
    } else {
      setEmailError("");
    }

    if (!name) {
      setNameError("Please enter your name");
    } else {
      setNameError("");
    }

    if (isValid) {
      saveAppRegistration();
    }
  };

  async function saveAppRegistration() {
    try {
      // If employer id doesn't exist, generate a new id and set the date created
      let data = {
        id: uuidv4(),
        dateCreated: new Date(),
        dateUpdated: new Date(),
        name: name,
        phoneNumber: phoneNumber,
        email: email,
      };
      if (school && school.length > 0) {
        data.schoolName = school;
      }
      const appRegistrationRef = collection(
        db,
        "mobile-app-email-registration"
      );
      await setDoc(doc(appRegistrationRef, data.id), data, { merge: true });

      alert(
        "Thanks for registering! Expect your link in 1-2 days!",
        "Registration Successful"
      );

      setEmail("");
      setSchool("");
      setName("");
      setPhoneNumber("");
    } catch (error) {
      console.error("Error submitting data:", error);
      throw new Error("Failed to submit data");
    }
  }

  return (
    <>
      <div>
        <section className="section-box pb-40 pt-30 hero-section-student">
          <div className="banner-hero hero-1 banner-homepage5">
            <div className="banner-inner">
              <div className="row">
                <div className="col-xl-7 col-lg-12">
                  <div className="block-banner block-banner-student d-block pt-30">
                    {/* heading-banner wow animate__animated animate__fadeInUp */}
                    <div>
                      <h1 className="text-5xl leading-snug font-extrabold">
                        Your dream job is just a swipe away!
                      </h1>
                    </div>
                    <div className="pt-20">
                      <form onSubmit={handleSubmit}>
                        <div className="mb-3">
                          <label htmlFor="name" className="form-label">
                            Your name:
                          </label>
                          <input
                            type="text"
                            className={`form-control ${
                              nameError && "is-invalid"
                            }`}
                            id="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                          />
                          {emailError && (
                            <div className="invalid-feedback">{nameError}</div>
                          )}
                        </div>
                        <div className="mb-3">
                          <label htmlFor="email" className="form-label">
                            Your email:
                          </label>
                          <input
                            type="email"
                            className={`form-control ${
                              emailError && "is-invalid"
                            }`}
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                          {emailError && (
                            <div className="invalid-feedback">{emailError}</div>
                          )}
                        </div>
                        <div className="mb-3">
                          <label htmlFor="phoneNumber" className="form-label">
                            Your phone number:
                          </label>
                          <PatternFormat
                            format="+1 (###) ### ####"
                            allowEmptyFormatting
                            mask="_"
                            id="phoneNumber"
                            name="phoneNumber"
                            value={phoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                          />
                        </div>

                        <div className="mb-3">
                          <label htmlFor="school" className="form-label">
                            Your school (optional):
                          </label>
                          <input
                            type="text"
                            className={`form-control`}
                            id="school"
                            value={school}
                            onChange={(e) => setSchool(e.target.value)}
                          />
                        </div>
                        <button type="submit" className="btn btn-primary">
                          Get app link
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="col-xl-5 col-lg-12 pt-20 d-none d-lg-block d-xl-block">
                  <div className="text-center">
                    <video width="350" height="350" controls autoPlay muted>
                      <source
                        src="/assets/videos/final_app.mp4"
                        type="video/mp4"
                      />
                    </video>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section-box mt-70 mb-40">
          <div className="container">
            <div className="text-start">
              <h2 className="text-4xl font-bold">Sign up now</h2>
              <p className="font-lg mt-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                With a mobile app that makes connecting with employers a swipe,
                AdultEd Pro has made finding a job easier than ever.
              </p>
              <p className="font-lg mt-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                Download the AdultEd Pro app here to get started! Add image
                section attached with IOS/Android links
              </p>
            </div>
            <div className="mt-70">
              <div className="row">
                <div className="col">
                  <img src="assets/imgs/AdultEd_Pro_App_Section.png"></img>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
