import { Outlet } from "react-router-dom";
import { useState } from 'react';
// import BackToTop from '../elements/BackToTop';
import Footer from './Footer';
import Header from './Header';
import Sidebar from './Sidebar';

const Layout = () => {
    const [openClass, setOpenClass] = useState('');

    const handleOpen = () => {
        document.body.classList.add("mobile-menu-active");
        setOpenClass("sidebar-visible")
    }

  const handleRemove = () => {
    console.log('onclose');
        if (openClass === "sidebar-visible") {
            setOpenClass("")
            document.body.classList.remove("mobile-menu-active");
        }
    }

  return (
    <>
      <div className="body-overlay-1" onClick={handleRemove} />
      <Header
        handleOpen={handleOpen}
        handleRemove={handleRemove}
        openClass={openClass}
      />
      <Sidebar openClass={openClass} onClose={handleRemove} />
      <main className="main pt-2">
        <Outlet />
      </main>
      <Footer />
      {/* <BackToTop /> */}
    </>
  );
};

export default Layout;