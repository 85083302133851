// jobsSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { db } from "../../services/firebase";
import {
  collection,
  doc,
  getDocs,
  setDoc,
  getDoc,
  query,
  where,
} from "firebase/firestore";
import { v4 as uuidv4 } from "uuid";

const setJobDoc = (jobRef) => {
  let job = undefined;

  if (jobRef.exists()) {
    job = jobRef.data();
  }
  if (!job.contactNumber && jobRef.data().contact) {
    job.contactNumber = jobRef.data().contact;
  }

  return job;
};

export const fetchJobs = createAsyncThunk("jobs/fetchJobs", async () => {
  const querySnapshot = await getDocs(collection(db, "jobs"));
  let job = querySnapshot.docs.map((doc) => setJobDoc(doc));
  return job;
});

export const fetchJobsByEmployerEmail = createAsyncThunk(
  "jobs/fetchJobsByEmployerEmail",
  async (email) => {
    const q = query(
      collection(db, "jobs"),
      where("employerEmail", "==", email)
    );

    const querySnapshot = await getDocs(q);
    let job = querySnapshot.docs.map((doc) => setJobDoc(doc));
    return job;
  }
);

export const setJob = createAsyncThunk(
  "jobs/setJob",
  async (jobData, { rejectWithValue }) => {
    try {
      if (!jobData.id) {
        jobData.id = uuidv4();
        jobData.dateCreated = new Date();
        jobData.datePosted = new Date();
      }
      jobData.dateUpdated = new Date();
      const jobsRef = collection(db, "jobs");
      await setDoc(doc(jobsRef, jobData.id), jobData, {
        merge: true,
      });
      return { id: jobData.id };
    } catch (error) {
      console.log("error.message", error.message);
      // You can customize the error handling here if needed
      return rejectWithValue(error.message);
    }
  }
);

export const fetchJob = createAsyncThunk("jobs/fetchJob", async (jobId) => {
  const jobDoc = await getDoc(doc(db, "jobs", jobId));
  return setJobDoc(jobDoc);
});

export const fetchJobByEmployerId = createAsyncThunk(
  "users/fetchUserByEmployerId",
  async (employerId, thunk) => {
    const q = query(
      collection(db, "jobs"),
      where("employerId", "==", employerId.trim())
    );
    const querySnapshot = await getDocs(q);
    let newData = [];
    querySnapshot.forEach((data, index) => {
      newData.push(data.data());
    });
    return thunk.fulfillWithValue(newData);
  }
);

const jobsSlice = createSlice({
  name: "jobs",
  initialState: {
    job: undefined,
    jobs: [],
    isLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchJobs.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchJobs.fulfilled, (state, action) => {
        state.isLoading = false;
        state.jobs = action.payload;
      })
      .addCase(fetchJobs.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(fetchJobsByEmployerEmail.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchJobsByEmployerEmail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.jobs = action.payload;
      })
      .addCase(fetchJobsByEmployerEmail.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(setJob.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(setJob.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(setJob.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(fetchJob.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchJob.fulfilled, (state, action) => {
        state.isLoading = false;
        state.job = action.payload;
      })
      .addCase(fetchJob.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(fetchJobByEmployerId.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchJobByEmployerId.fulfilled, (state, action) => {
        state.isLoading = false;
        state.jobs = action.payload;
        state.error = "";
      })
      .addCase(fetchJobByEmployerId.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export default jobsSlice.reducer;
export const { actions: jobsActions } = jobsSlice;
