import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { db } from "../../services/firebase";
import {
  collection,
  doc,
  getDocs,
  addDoc,
  setDoc,
  getDoc,
  query,
  where,
  updateDoc,
  arrayUnion,
  orderBy,
  writeBatch
} from "firebase/firestore";

// Utility function to format chat data
const setChatDoc = (chatRef) => {
  let chat = undefined;
  if (chatRef.exists()) {
    chat = chatRef.data();
    chat["id"] = chatRef.id
  }

  return chat;
};

// Fetch all chats for a user
export const fetchChats = createAsyncThunk(
    "chats/fetchChats",
    async (userId) => {
        try {
            const q = query(
              collection(db, "chats"),
              where("participants", "array-contains", userId),
              orderBy("lastMessageTimestamp", "desc") // Order by lastMessageTimestamp in descending order
            );
            const querySnapshot = await getDocs(q);
            let chats = querySnapshot.docs.map((doc) => setChatDoc(doc));
            return chats;
        } catch (error) {
            console.log("chat get error " , error)
        }
    }
  );

export const fetchMessages = createAsyncThunk(
    "chats/fetchMessages",
    async ({ chatId }, thunkAPI) => {
      try {
        console.log("inside fetch chat message", chatId)
        const messagesRef = collection(db, "chats", chatId, "messages");
        console.log("messagesRef", messagesRef)
        console.log("chatId", chatId)
        const q = query(messagesRef, orderBy("timestamp")); // Assuming messages are ordered by a 'timestamp' field
        const querySnapshot = await getDocs(q);
        console.log("querySnapshot...", querySnapshot)
        
        let messages = [];
        querySnapshot.forEach((doc) => {
          messages.push({ id: doc.id, ...doc.data() });
        });
        console.log("messages inside slice ", messages)
        return messages;
      } catch (error) {
        console.log("fetch chat messages error" , error)
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  );
  

  export const getUnreadMessageCount = createAsyncThunk(
    "chats/getUnreadMessageCount",
    async ({ chatId, userId }) => {
        try {
            console.log("chatId" ,"userId" , chatId,userId )
          const messagesRef = collection(db, "chats", chatId, "messages");
          const q = query(messagesRef, where("readBy", "not-in", [[userId.trim()]]));
          const querySnapshot = await getDocs(q);
          console.log("querySnapshot.docs" , querySnapshot.docs)
          return querySnapshot.docs.length;
        } catch (error) {
            console.log("error in count" , error)
        }
    }
  );
// Fetch a specific chat by ID
export const fetchChat = createAsyncThunk("chats/fetchChat", async (chatId) => {
  const chatDoc = await getDoc(doc(db, "chats", chatId));
  return setChatDoc(chatDoc);
});

// Create or update a chat
export const setChat = createAsyncThunk(
  "chats/setChat",
  async (chatData, { rejectWithValue }) => {
    try {
      const chatsRef = collection(db, "chats");
      const chatRef = doc(chatsRef, chatData.id || doc(chatsRef).id);
      await setDoc(chatRef, chatData, { merge: true });
      return { id: chatRef.id };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Add a message to a chat
export const sendMessage = createAsyncThunk(
  "chats/sendMessage",
  async ({ chatId, messageData }, { rejectWithValue }) => {
    try {

      console.log("inside send message",chatId )
      const messagesRef = collection(db, "chats", chatId, "messages");
      const messageRef = await addDoc(messagesRef, {
        ...messageData
      });

      console.log("messageRef.id" , messageRef.id)
      if (messageRef.id) {
        // Update the chats collection with the last message info
        const chatRef = doc(db, "chats", chatId);
        await updateDoc(chatRef, {
          lastMessage: messageData.text, // Assuming messageData contains a 'text' field
          lastMessageTimestamp: new Date() // Update with the timestamp of the message
        });
      }

      return {
        ...messageData,
        id: messageRef.id,
        timestamp: messageData.timestamp
      };
    } catch (error) {
      console.log("send message erro ", error )
      return rejectWithValue(error.message);
    }
  }
);

// Mark single message as read
export const markMessageAsRead = createAsyncThunk(
  "chats/markMessageAsRead",
  async ({ chatId, messageId, userId }, { rejectWithValue }) => {
    try {
      const messageRef = doc(db, "chats", chatId, "messages", messageId);
      await updateDoc(messageRef, {
        readBy: arrayUnion(userId)
      });
      return { messageId, userId };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// mark all messages in single chat as read
// export const markAllMessagesAsRead = createAsyncThunk(
//   "chats/markAllMessagesAsRead",
//   async ({ chatId, userId }, { rejectWithValue }) => {
//     try {
//       const messagesRef = collection(db, "chats", chatId, "messages");
//       const q = query(messagesRef, where("readBy", "not-in", [userId]));
//       const querySnapshot = await getDocs(q);

//       // Begin a batch write
//       const batch = db.batch();

//       querySnapshot.forEach((doc) => {
//         const messageDocRef = doc.ref;
//         batch.update(messageDocRef, {
//           readBy: arrayUnion(userId)
//         });
//       });

//       // Commit the batch
//       await batch.commit();

//       return { chatId, userId };
//     } catch (error) {
//       return rejectWithValue(error.message);
//     }
//   }
//   );
  
  export const markAllMessagesAsRead = createAsyncThunk(
    "chats/markAllMessagesAsRead",
    async ({ chatId, userId }, { rejectWithValue }) => {
      try {
      const messagesRef = collection(db, "chats", chatId, "messages");
      const q = query(messagesRef, where("readBy", "not-in", [userId]));
      const querySnapshot = await getDocs(q);
      
      // Begin a batch write
      const batch = writeBatch(db);
      
      querySnapshot.forEach((doc) => {
        const messageDocRef = doc.ref;
        batch.update(messageDocRef, {
          readBy: arrayUnion(userId)
        });
      });
      
      // Commit the batch
      await batch.commit();
      
      return { chatId, userId };
    } catch (error) {
      console.log("error insife mark all as read " , error)
      return rejectWithValue(error.message);
    }
  }
);
const   initialState= {
  chats: [],
  currentChat: [],
  isLoading: false,
  error: null,
}
const chatsSlice = createSlice({
  name: "chats",
  initialState,
  reducers: {
    resetState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchChats.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchChats.fulfilled, (state, action) => {
        state.isLoading = false;
        state.chats = action.payload;
      })
      .addCase(fetchChats.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(fetchMessages.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchMessages.fulfilled, (state, action) => {
        state.isLoading = false;
        state.currentChat = action.payload;
      })
      .addCase(fetchMessages.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
  },
});

export default chatsSlice.reducer;
export const { actions: chatsActions } = chatsSlice;
