import { React, useState, useRef } from 'react';
import { useDispatch } from "react-redux";
import { saveContactForm } from "../store/reducers/contactSlice";

export default function About() {
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phone: "",
        message: "",
    });
    const [formErrors, setErrors] = useState({});
    const [checkboxChecked, setCheckboxChecked] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const [selectedValue, setSelectedValue] = useState(''); 

    const handleSelectChange = (e) => {
      setSelectedValue(e.target.value);
    };

    const partnerSignupRef = useRef(null);
    const handleCheckboxChange = (e) => {
        setCheckboxChecked(e.target.checked);
    };


    const handleInputChange = (e) => {
        setFormData((prevData) => ({
            ...prevData,
            [e.target.name]: e.target.value,
        }));
        setSuccessMessage("");
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const errors = validateForm(formData);
        if (Object.keys(errors).length !== 0) {
            setErrors(errors);
            return;
        }

        const data = {
          name: formData.name,
          email: formData.email,
          phone: formData.phone,
          message: formData.message,
          contactFormLocation: "About Adult School Jobs",
        };
        await dispatch(saveContactForm(data)).then((x) => {
            setSuccessMessage(
                "Submitted successfully, Our Representative will get back to you soon"
            );
            // Reset the form and errors
            setFormData({
                name: "",
                email: "",
                phone: "",
                message: "",
            });
            setErrors({});
        });
    };

    function validateForm(formData) {
        const errors = {};

        if (!formData.name.trim()) {
            errors.name = "Name is required";
        } else if (!/^[a-zA-Z ]+$/.test(formData.name)) {
            errors.name = "Name should only contain alphabets";
        }

        if (!formData.phone.trim()) {
            errors.phone = "Phone number is required";
        } else if (!/^\d+$/.test(formData.phone)) {
            errors.phone = "Invalid phone number format";
        }
        

        if (!formData.email.trim()) {
            errors.email = "Email is required";
        } else if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(formData.email)) {
            errors.email = "Invalid email format";
        }

        if (!formData.message.trim()) {
            errors.message = "Message is required";
        }


        if (!checkboxChecked) {
            errors.check_box = "Please agree to the terms and policy";
        }

        return errors;
    }
    

    return (
        <div className='container'>
            <section className='section-box'>
                <div className="bg-white">

                    <div className="relative isolate px-6 pt-10 lg:px-8" >


                        <div className=" mb-2 py-11  rounded-lg " style={{ backgroundImage: 'url("../assets/imgs/page/about/bg-aboutus.jpg")', backgroundSize: "cover", backgroundPosition: 'bottom center' }}>
                            <div className=" p-4 w-full col-span-2 md:col-span-1 lg:col-span-2 xl:col-span-2 rounded-lg">
                                <div className='py-8'>
                                    <h1 className="text-lg leading-8 text-white pb-1 text-center p-3" style={{ backgroundColor: "#05264e" }}>
                                        <a href='/' style={{ color: "#00ff45" }} >Adultschooljobs.com </a>is a site dedicated to helping the Adult School Community, including institutions, students, and employers.
                                    </h1>
                                    <p className="mt-8 text-lg leading-8 text-white text-center p-3 " style={{ backgroundColor: "#05264e" }}>
                                        We help institutions by building a pipeline of students through our partnership with    <a href='http://hiddenworkers.org/' target='_blank' rel="noreferrer" style={{ color: "#00ff45" }}>hiddenworkers.org </a> , an advocacy site that is dedicated to helping the over 27m hidden workers (that includes adult school students) improve career outcomes.
                                    </p>
                                </div>
                            </div>

                        </div>



                        <div className="grid grid-cols-1 mt-16 sm:grid-cols-2  lg:grid-cols-3 xl:grid-cols-3 gap-4 border rounded-lg">
                            <div className="bg-gray-100 p-4 w-full col-span-2 md:col-span-1 lg:col-span-2 xl:col-span-2 rounded-lg">
                                <div>
                                    <h2 className="text-2xl leading-8 text-gray-600 pb-1">
                                        Our work with institutions involves:
                                    </h2>
                                    <p className="mt-6 text-lg leading-8 text-gray-600">
                                        1: Helping to build/enhance the pipeline of students who attend Adult Schools
                                    </p>
                                    <p className="mt-6 text-lg leading-8 text-gray-600">
                                        2: Helping institutions improve career outcomes with a dedicated, configurable employment platform where students can find positions with employers receptive to their unique needs. We not only give these solutions to institutions, we also help them track student outcomes which are essential for reporting and funding.
                                    </p>
                                </div>
                            </div>
                            <div className="p-4 flex justify-center items-center">
                                <img
                                    className="bdrd-10 w-[500px]"
                                    src="../assets/imgs/page/about/college.png"
                                    alt="jobBox"
                                />
                            </div>
                        </div>

                        <div className="grid grid-cols-1 my-4 sm:grid-cols-2  lg:grid-cols-3 xl:grid-cols-3 gap-4 border rounded-lg">
                            <div className="bg-gray-100 p-4 w-full col-span-2 md:col-span-1 lg:col-span-2 xl:col-span-2 rounded-lg">
                                <div>
                                    <h2 className="text-2xl leading-8 text-gray-600 pb-1 text-bold">
                                        Our work with students involves:
                                    </h2>
                                    <p className="mt-6 text-lg leading-8 text-gray-600">
                                        1: Giving them a dedicated platform from which to be introduced to employers,
                                    </p>
                                    <p className="mt-6 text-lg leading-8 text-gray-600">
                                        2: Simplifying job descriptions and application processes by cutting out unnecessary jargon and eliminating steps to employer introductions.
                                    </p>
                                    <p className="mt-6 text-lg leading-8 text-gray-600">
                                        3:Giving students technology solutions that are enabling and inclusive rather than restrictive and exclusive. We build websites and apps that break down barriers to employment and help students find meaningful employment.
                                    </p>
                                </div>
                            </div>
                            <div className="p-4 flex justify-center items-center">
                                <img
                                    className="bdrd-10 w-[500px]"
                                    src="../assets/imgs/page/about/about-icon.png"
                                    alt="jobBox"
                                />
                            </div>
                        </div>

                        <div className="grid grid-cols-1 my-4 sm:grid-cols-2  lg:grid-cols-3 xl:grid-cols-3 gap-4 border rounded-lg">
                            <div className="bg-gray-100 p-4 w-full col-span-2 md:col-span-1 lg:col-span-2 xl:col-span-2 rounded-lg">
                                <div>
                                    <h2 className="text-2xl leading-8 text-gray-600 pb-1 text-bold">
                                        Our work with employers involves:
                                    </h2>
                                    <p className="mt-6 text-lg leading-8 text-gray-600">
                                        We educate employers and help them find suitable candidates amongst this often overlooked segment of the labor market. With unprecedented labor shortages, employers cannot afford to not recruit from Adult Schools, which are full of motivated self-starters ready to invest in and improve their careers.
                                    </p>
                                </div>
                            </div>
                            <div className="p-4 flex justify-center items-center">
                                <img
                                    className="bdrd-10 w-[500px]"
                                    src="../assets/imgs/page/about/employers.png"
                                    alt="jobBox"
                                />
                            </div>
                        </div>

                        <div className="grid grid-cols-1  mb-2 border rounded-lg">
                            <div className="bg-gray-100 p-4 w-full col-span-2 md:col-span-1 lg:col-span-2 xl:col-span-2 rounded-lg">
                                <div>

                                    <p className="mt-6 text-lg leading-8 text-gray-600">
                                        We partner with schools, employers, NGOs and other groups to help improve career outcomes.
                                    </p>
                                </div>
                            </div>

                        </div>

                        <section className='container section-box mt-80  ' >


                            <div ref={partnerSignupRef} className='container w-full  w-lg-3/5 border rounded-lg px-8 py-8 bg-gray-100' >
                                <div className='row'>
                                    <div className='w-full mb-40'>

                                        <h2 className='mt-5 mb-10 text-3xl font-bold'>Contact us</h2>
                                        <p className='font-md color-text-paragraph-2' id = 'about-contact-us'>
                                            We would love the hear from you - please use the contact form below to connect with us!
                                        </p>
                                        <form
                                            className='contact-form-style mt-30'
                                            id='contact-form'
                                            action='#'
                                            method='post'
                                        >
                                            <div
                                                className='row wow animate__animated animate__fadeInUp'
                                                data-wow-delay='.1s'
                                            >
                                                <div className='col-lg-6 col-md-6' >
                                                    <div className='input-style mb-20'>
                                                        <input
                                                            className='font-sm color-text-paragraph-2'
                                                            name='name'
                                                            placeholder='Name'
                                                            type='text'
                                                            value={formData.name}
                                                            onChange={handleInputChange}
                                                            required
                                                        />
                                                        {formErrors.name && (
                                                            <div className='text-danger'>{formErrors.name}</div>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className='col-lg-6 col-md-6'>
                                                    <div className='input-style mb-20'>
                                                        <input
                                                            className='font-sm color-text-paragraph-2'
                                                            name='email'
                                                            placeholder='Your email'
                                                            type='email'
                                                            value={formData.email}
                                                            onChange={handleInputChange}
                                                            required
                                                        />
                                                        {formErrors.email && (
                                                            <div className='text-danger'>{formErrors.email}</div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className='col-lg-6 col-md-6'>
                                                    <div className='input-style mb-20'>
                                                        <input
                                                            className='font-sm color-text-paragraph-2'
                                                            name='phone'
                                                            placeholder='Phone number'
                                                            type='tel'
                                                            value={formData.phone}
                                                            onChange={handleInputChange}
                                                            required
                                                        />
                                                        {formErrors.phone && (
                                                            <div className='text-danger'>{formErrors.phone}</div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className='col-lg-6 col-md-6'>
                                                    <div className='input-style mb-20'>
                                                        
                                                        <select
                                                            className="w-full bg-white py-3 px-3 border rounded font-sm color-text-paragraph-2"
                                                            value={selectedValue}
                                                            onChange={handleSelectChange}
                                                        >
                                                            <option value="">Who are you </option>
                                                            <option value="option1">Student</option>
                                                            <option value="option2">Institution</option>
                                                            <option value="option3">Employer</option>
                                                            
                                                        </select>
                                                        
                                                    </div>
                                                </div>
                                                <div className='col-lg-12 col-md-12'>
                                                    <div className='textarea-style mb-30'>
                                                        <textarea
                                                            className='font-sm color-text-paragraph-2 px-4 py-2'
                                                            name='message'
                                                            placeholder='Type your message here'
                                                            value={formData.message}
                                                            onChange={handleInputChange}
                                                            required
                                                        />
                                                        {formErrors.message && (
                                                            <div className='text-danger'>{formErrors.message}</div>
                                                        )}
                                                    </div>
                                                    <button
                                                        className='submit btn btn-send-message bg-[#05264e] px-3 py-2'
                                                        type='submit'
                                                        onClick={handleSubmit}
                                                    >
                                                        Send message
                                                    </button>
                                                    {formErrors.check_box && (
                                                        <div className='text-danger'>{formErrors.check_box}</div>
                                                    )}
                                                    <label className='ml-20'>
                                                        <input
                                                            className='float-start mr-5 mt-6'
                                                            type='checkbox'
                                                            checked={checkboxChecked}
                                                            onChange={handleCheckboxChange}
                                                            required
                                                        />{" "}
                                                        By clicking send message button, you agree our{" "}
                                                        <a href='/legal/terms-of-service'>Terms of Service</a>.
                                                    </label>
                                                    {successMessage && (
                                                        <div className='text-success'>{successMessage}</div>
                                                    )}

                                                </div>
                                            </div>
                                        </form>
                                        <p className='form-messege' />
                                    </div>

                                </div>
                            </div>
                        </section>





                        <div
                            className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
                            aria-hidden="true"
                        >
                            <div
                                className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
                                style={{
                                    clipPath:
                                        "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)"
                                }}
                            />
                        </div>
                    </div>
                </div>

            </section>
        </div>
    )
}
