const TOS = () => {

  return (
<div class="legal-content container section-full">
  <h1>Terms of Service</h1>
  <p>
    Welcome to AdultEdPro.com! These Terms of Service (the "Terms") govern
    your use of our website and services. By using our website, you agree to
    these Terms. If you do not agree to these Terms, you may not use our website
    or services.
  </p>
  <h2>Use of Our Website</h2>
  Our website is intended for use by individuals seeking job opportunities and
  employers seeking candidates for job opportunities. You may not use our
  website for any illegal or unauthorized purpose.
  <h2>User Accounts</h2>
  To use certain features of our website, you may need to create an account. You
  are responsible for maintaining the confidentiality of your account
  information and for all activities that occur under your account.
  <h2>Job Listings and Applications</h2>
  Employers may post job listings on our website, and job seekers may apply for
  those listings through our website. We do not guarantee that job listings are
  accurate or that job seekers will be hired for any particular job. We are not
  responsible for any interactions between employers and job seekers.
  <h2>User Content</h2>
  You may post content on our website, including your resume and other materials
  related to your job search. By posting content on our website, you grant us a
  non-exclusive, royalty-free, perpetual, irrevocable, and fully sublicensable
  right to use, reproduce, modify, adapt, publish, translate, create derivative
  works from, distribute, perform, and display such content throughout the world
  in any media.
  <h2>Intellectual Property</h2>
  Our website and its content are protected by copyright, trademark, and other
  laws. You may not copy, modify, distribute, or create derivative works based
  on our website or its content without our prior written consent.
  <h2>Prohibited Conduct</h2>
  You may not use our website to:
  <ul>
    <li>
      Transmit any content that is unlawful, defamatory, harassing, abusive, or
      fraudulent;
    </li>
    <li>
      Impersonate any person or entity, or falsely state or otherwise
      misrepresent your affiliation with a person or entity;
    </li>
    <li>
      Interfere with or disrupt our website or servers or networks connected to
      our website;
    </li>
    <li>
      Use any robot, spider, or other automated means to access our website for
      any purpose; or
    </li>
    <li>
      Engage in any activity that violates any applicable law or regulation.
    </li>
  </ul>
  <h2>Disclaimer of Warranties</h2>
  Our website and services are provided on an "as is" and "as available" basis.
  We make no representations or warranties of any kind, express or implied, as
  to the operation of our website or the information, content, materials, or
  services included on our website.
  <h2>Limitation of Liability</h2>
  We will not be liable for any direct, indirect, incidental, special,
  consequential, or punitive damages arising out of or related to these Terms,
  our website, or our services.
  <h2>Indemnification</h2>
  You agree to indemnify and hold us harmless from any claims, losses, damages,
  liabilities, costs, and expenses (including reasonable attorneys' fees)
  arising out of or related to your use of our website or your violation of
  these Terms.
  <h2>Termination</h2>
  We may terminate or suspend your account and access to our website at any time
  and for any reason, without notice or liability.
  <h2>Governing Law and Dispute Resolution</h2>
  These Terms and your use of our website are governed by the laws of [Insert
  jurisdiction]. Any dispute arising out of or related to these Terms or your
  use of our website shall be resolved exclusively by binding arbitration in
  accordance with the [Insert arbitration rules and procedures] in [Insert
  jurisdiction].
  <h2>Changes to these Terms</h2>
  We may update these Terms from time to time. We will notify you of any
  material changes by posting the updated Terms on our website. Your continued
  use of our website after the changes are posted will indicate your acceptance
  of the updated Terms.
</div>
 );
};

export default TOS;