import { Link, useLocation, useNavigate } from "react-router-dom";
import "react-perfect-scrollbar/dist/css/styles.css";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { authActions } from "../../store/reducers/authSlice";
import { signOut } from "firebase/auth";
import { partnersActions } from "../../store/reducers/partnerSlice";
import { usersActions } from "../../store/reducers/usersSlice";
// import UserRolesEnum from "../utills/enums";

const Sidebar = (props) => {
//  const loginUserRole = localStorage.getItem("Role");
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const handleToggle = () => {
    
    console.log('props', props);
    props.onClose();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const handleSignOut = () => {
    dispatch(signOut()).then(() => {
      dispatch(authActions.resetState());
      dispatch(partnersActions.resetState());
      dispatch(usersActions.resetState());
      localStorage.setItem("isLoggedIn",false)
      localStorage.removeItem("userId")
      navigate("/");
    });
  };

  return (
    <>
      <div
        className={`mobile-header-active mobile-header-wrapper-style perfect-scrollbar ${props.openClass}`}
      >
        <div className="mobile-header-wrapper-inner">
          <div className="mobile-header-content-area">
            <div className="mobile-menu-wrap mobile-header-border">
              {/* mobile menu start*/}
              <nav>
                <ul className="mobile-menu font-heading">
                  <li>
                    <span onClick={handleToggle}>
                      <Link to="/">Home</Link>
                    </span>
                  </li>
                  {/* <li>
                    <span onClick={handleToggle}>
                      <Link to="/jobs">Find a Job</Link>
                    </span>
                  </li> */}
                  <li>
                    <span onClick={handleToggle}>
                      {<Link to="/student">Students</Link>}
                    </span>
                  </li>

                  <li>
                    <span onClick={handleToggle}>
                      <Link to="/employer">Employers</Link>
                    </span>
                  </li>
                  <li>
                    <span onClick={handleToggle}>
                      <Link to="/institutions">Institutions</Link>
                    </span>
                  </li>
                  <li>
                    <span onClick={handleToggle}>
                      <Link to="/about">About Us</Link>
                    </span>
                  </li>
                </ul>
              </nav>
            </div>
            <div className="mobile-account">
              {auth?.auth?.email ? (
                <button
                  className="btn btn-outline-dark"
                  onClick={handleSignOut}
                >
                  Sign Out
                </button>
              ) : (
                <div className="block-signin">
                  <ul className="mobile-menu font-heading">
                    <li>
                      <span onClick={handleToggle}>
                        <Link to="/">Home</Link>
                      </span>
                </li><li>
                        <span onClick={handleToggle}>
                          <a
                            className="text-brand-2 font-weight-bold"
                            href="https://app.adultedpro.com/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Sign in
                          </a>
                        </span>

                    </li>
                  </ul>
                </div>
              )}
            </div>
            {/* <div className="mobile-account">
                <h6 className="mb-10">Your Account</h6>
                <ul className="mobile-menu font-heading">
                  <li>
                    <Link to="#">Profile</Link>
                  </li>
                  <li>
                    <Link to="#">Work Preferences</Link>
                  </li>
                  <li>
                    <Link to="#">Account Settings</Link>
                  </li>
                  <li>
                    <Link to="#">Go Pro</Link>
                  </li>
                  <li>
                    <Link to="/page-signin">Sign Out</Link>
                  </li>
                </ul>
              </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
