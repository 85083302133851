import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { collection, query, addDoc, orderBy, where, getDocs, doc, setDoc , onSnapshot } from "firebase/firestore";
import { db } from "../../services/firebase";


const chatMessagesCollection = collection(db, "chatMessages");
let unsubscribeChatMessagesListener;

const setChatMessage = (messageDoc) => {
  let message = undefined;
  if (messageDoc.exists()) {
    message = {
      id: messageDoc.id,
      messages: messageDoc.data().messages,
      usersChatsId: messageDoc.data().usersChatsId
    };
  }
  return message;
};

export const sendMessage = createAsyncThunk(
  "chatMessages/sendMessage",
  async ({ usersChatsId, existing_chat_messages_id, messages }) => {
    const messageData = {
      usersChatsId,
      messages
    };

  
      // If no existing_chat_messages_id is provided, add a new document with the new message.
      const messageDocRef=  await setDoc(doc(chatMessagesCollection, existing_chat_messages_id.trim() ), messageData,
      {
        merge: true
      }).then(() =>{
        return{
        id: messageDocRef.id,
        ...messageData,
        }
      });
    }
  // }
);



export const fetchMessages = createAsyncThunk(
  "chatMessages/fetchMessages",
  async (senderId) => {
    const q = query(
      collection(db, "chatMessages"),
      // where("senderId", "==", senderId),
      orderBy("message")
    );
    const querySnapshot = await getDocs(q);
    const messages = querySnapshot.docs.map((doc) => setChatMessage(doc));
    return messages;
  }
);

export const updateMessages = createAsyncThunk(
  "chatMessages/updateMessages",
  async ({ senderId, message }) => {
    const messageData = {
      message,
      senderId,
      timestamp: new Date(),
    };
    await setDoc(doc(db, "chatMessages", senderId), messageData, {
      merge: true,
    });
    return messageData;
  }
);

export const addNewMessage = createAsyncThunk(
  "chatMessages/addNewMessage",
  async ({ usersChatsId, messages}) => {
    const messageData = {
      usersChatsId,
      messages
    };
    await addDoc(collection(db, "chatMessages"), messageData, {
      merge: true,
    });
    return messageData;
  }
);

export const listenForMessages = (userChatsId) => (dispatch) => {
  console.log('userChatsId inside', userChatsId);
  // const dispatch = useDispatch();
  if(userChatsId?.trim())
  {
    const q = query(
      collection(db, "chatMessages"),
      where("usersChatsId", "==", userChatsId?.trim())
      // orderBy("timestamp")  // uncomment if you want to order by timestamp
    );

     onSnapshot(q, (doc) => {
      console.log("Current data: ", doc);
      const messages = doc.docs.map((item) => setChatMessage(item));
      dispatch(chatMessagesActions.updateLiveMessages(messages));
    }, (error) => {
      // handle the error here
      console.error("Error listening for chat messages: ", error);
    });
  }

};

export const unsubscribeMessages = () => {
  if (unsubscribeChatMessagesListener) {
    unsubscribeChatMessagesListener();
  }
};

const initialState=  {
  messages: [],
  isLoading: false,
  error: null,
}
 const chatMessagesSlice = createSlice({
  name: "chatMessages",
  initialState,
  
  reducers: {
    resetState: () => initialState,
    updateLiveMessages: (state, action) => {
      state.messages = action.payload;
   }
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendMessage.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(sendMessage.fulfilled, (state, action) => {
        state.isLoading = false;
        state.messages.push(action.payload);
      })
      .addCase(sendMessage.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(fetchMessages.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchMessages.fulfilled, (state, action) => {
        state.isLoading = false;
        state.messages = action.payload;
      })
      .addCase(fetchMessages.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(updateMessages.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateMessages.fulfilled, (state, action) => {
        state.isLoading = false;
        // Update the message in the state
        const updatedMessage = action.payload;
        const index = state.messages.findIndex(
          (message) => message.id === updatedMessage.id
        );
        if (index !== -1) {
          state.messages[index] = updatedMessage;
        }
      })
      .addCase(updateMessages.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(addNewMessage.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(addNewMessage.fulfilled, (state, action) => {
        state.isLoading = false;
        // Add the new message to the state
        const newMessage = action.payload;
        state.messages.push(newMessage);
      })
      .addCase(addNewMessage.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      // .addCase(listenForMessages.pending, (state) => {
      //   state.isLoading = true;
      //   state.error = null;
      // })
      // .addCase(listenForMessages.fulfilled, (state, action) => {
      //   state.isLoading = false;
      //   state.messages = action.payload;
      // })
      // .addCase(listenForMessages.rejected, (state, action) => {
      //   state.isLoading = false;
      //   state.error = action.error.message;
      // })
      .addCase(chatMessagesActions.resetState, (state) => initialState);
  },
});

export default chatMessagesSlice.reducer;
export const { actions: chatMessagesActions } = chatMessagesSlice;
