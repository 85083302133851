import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { collection, query,  where, getDocs, or, setDoc, doc, orderBy } from "firebase/firestore";
import { db } from "../../services/firebase";
import { get_combinedusersChatsId } from "../../components/utills/messagedata";
let unsubscribeChatListener; // Variable to hold the unsubscribe function

// Helper function to parse chat data
const setChatDoc = (chatDoc) => {
  console.log('chat set' ,chatDoc.data() )
  let chat = undefined;
  if (chatDoc.exists()) {
    console.log("chatDoc.data()...." , chatDoc.data())
    chat = {
      id: chatDoc.id,
      message: chatDoc.data().message,
      receiverId: chatDoc.data().receiverId,
      senderId: chatDoc.data().senderId,
      timestamp: chatDoc.data().timestamp,
      user1 : chatDoc.data()?.user1,
      user2 : chatDoc.data()?.user2,
      photoUrl : chatDoc.data().userinfo?.photoUrl,
      displayName : chatDoc.data().userinfo?.displayName,
      lastMessage : chatDoc.data().userinfo?.lastMessage,
      lastReadMessageId : chatDoc.data().userinfo?.lastReadMessageId,
      unreadCounts :chatDoc.data().unreadCounts,
      senderName : chatDoc.data()?.senderinfo?.displayName ? chatDoc.data()?.senderinfo?.displayName : "",
      sender_photo_url :chatDoc.data()?.senderinfo?.photoUrl ? chatDoc.data()?.senderinfo?.photoUrl : ""
    };
  }
  console.log('chattt' , chat)
  return chat;
};

function createObjectWithDefinedValues(obj) {
  const result = {};

  for (const key in obj) {
    if (obj[key] !== undefined && obj[key] !== null) {
      result[key] = obj[key];
    }
  }

  return result;
}

export const addUserChatsData = createAsyncThunk(
  "usersChats/addUserChatsData",
  async ({ receiverId, senderId, displayName, photoUrl, lastMessage, chatId, unreadCounts,
    sender_name, sender_photo_url, reciever_email, user1, user2 , to_send_email}) => {
    try {
      if (!chatId) {
        chatId = get_combinedusersChatsId(user1?.id, user2?.id);
      }

      if (!chatId) {
        return;
      }
      let updated_user1 = createObjectWithDefinedValues(user1);
      let updated_user2 = createObjectWithDefinedValues(user2);
      // Provide default values for potentially undefined fields
      const chatData = {
        receiverId: receiverId || "", // Default to an empty string
        senderId: senderId || "", // Default to an empty string
        timestamp: new Date(),
        unreadCounts: unreadCounts, // Default to 0
        userinfo: {
          displayName: displayName || "", // Default to an empty string
          photoUrl: photoUrl || "", // Default to an empty string
          lastMessage: lastMessage || "", // Default to an empty string
        },
        senderinfo: {
          displayName: sender_name || "", // Default to an empty string
          photoUrl: sender_photo_url || "", // Default to an empty string
        },
        user1:updated_user1,
        user2: updated_user2,
      };

      const chatref = collection(db, "usersChats");
      const chatDocRef = await setDoc(doc(chatref, chatId), chatData, {
        merge: true,
      });
    
      console.log("Chat data added successfully:", chatDocRef?.id);

      return {
        id: chatDocRef?.id,
        ...chatData,
      };
    } catch (error) {
      console.error("Error adding chat data:", error);
      throw error; // Re-throw the error to be caught by the caller
    }
  }
);



// Async thunk to listen for incoming chat messages
export const listenForChatMessages = createAsyncThunk(
  "usersChats/listenForChatMessages",
  async (userId) => {
    try {
      const querySnapshot = await getDocs(query(collection(db, "usersChats"), or(
        where("receiverId", "==", userId.trim()), 
      where( "senderId", "==", userId.trim())
      ),
      orderBy("timestamp", "desc")
      )
      );
      let users = querySnapshot.docs.map((doc) => setChatDoc(doc));
      return users;
      
    } catch (error) {
      
      console.log("error in userchats" , error)
    }

  }
);


export const GetUnreadChatMessages = createAsyncThunk(
  "usersChats/UnreadChatMessages",
  async (userId) => {
    
    console.log("receiverId.." , userId)
    // const querySnapshot = await getDocs(query(collection(db, "usersChats"), where("receiverId", "==", userId.trim(), or , "senderId", "==", userId.trim())));
    const querySnapshot = await getDocs(query(collection(db, "usersChats"), 
      where("receiverId", "==", userId.trim())
      //  ,  where("unreadCount", ">", 0 )
    ,
    // orderBy("timestamp")
    )
    );
    // const querySnapshot = await getDocs(query(collection(db, "usersChats"), where("id", "in", userId.trim())));

    let users = querySnapshot.docs.map((doc) => setChatDoc(doc));
    return users;

  }
);

export const unsubscribeChat = () => {
  if (unsubscribeChatListener) {
    unsubscribeChatListener();
  }
};



// Action creator to update chatMessages state
export const updateChatMessages = (chatMessages) => ({
  type: "usersChats/updateChatMessages",
  payload: chatMessages,
});

const initialState = {
  chatMessages: [],
  unreadChatMessages :[],
  chat : undefined,
  isLoading: false,
  error: null,
};

const usersChatsSlice = createSlice({
  name: "usersChats",
  initialState,
  reducers: {
    resetState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(addUserChatsData.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(addUserChatsData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.chatMessages.push(action.payload);
      })
      .addCase(addUserChatsData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(listenForChatMessages.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(listenForChatMessages.fulfilled, (state, action) => {
        state.isLoading = false;
        state.chatMessages = action.payload;
      })
      .addCase(listenForChatMessages.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(GetUnreadChatMessages.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(GetUnreadChatMessages.fulfilled, (state, action) => {
        state.isLoading = false;
        state.unreadChatMessages = action.payload;
      })
      .addCase(GetUnreadChatMessages.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(usersChatsActions.resetState, (state) => initialState);
  },
});

export default usersChatsSlice.reducer;
export const { actions: usersChatsActions } = usersChatsSlice;
