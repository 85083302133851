/* eslint-disable */
import { Link } from "react-router-dom";
import CategoryTab2 from "../components/elements/CategoryTab2";
import BlogSlider from "../components/sliders/Blog";
import SchoolSearch from "../components/school_search/school_search";

import { useState } from "react";
import FloatingPopUp from "../components/elements/PopUp";

const Home = () => {
  const [keyword, setKeyword] = useState("");
  const [state, setState] = useState("CA");

  const handleKeywordChange = (event) => {
    setKeyword(event.target.value);
  };

  const handleStateChange = (event) => {
    setState(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    let query = "/jobs";
    let isFirstParam = true;
    if (keyword) {
      query += `?keyword=${encodeURIComponent(keyword)}`;
      isFirstParam = false;
    }

    if (state) {
      query = isFirstParam ? query + "?" : query + "&";
      query += `state=${encodeURIComponent(state)}`;
    }

    window.location.href = query;
  };

  return (
    <>
      <div>
        <section className="section-box pb-40 pt-30 hero-section-home">
          <div className="banner-hero hero-1 banner-homepage5">
            <div className="banner-inner">
              <div className="row">
                <div className="col-xl-7 col-lg-12">
                  <div className="block-banner">
                    {/* heading-banner wow animate__animated animate__fadeInUp */}
                    <h1 className="text-5xl leading-snug font-extrabold">
                      The App for Adult Education
                      <br />
                      {/* Help your students! */}
                    </h1>
                    <div
                      className="banner-description mt-20 wow animate__animated animate__fadeInUp"
                      data-wow-delay=".1s"
                    >
                      We partner with Adult Learning Institutions and Job
                      centers to provide a comprehensive solution that makes you
                      more efficient and your students more successful!
                    </div>
                    <div className="mt-30">
                      {/* <Link to="/jobs" className="btn btn-border-brand-2 m-1">
                        Find a Job
                      </Link> */}
                      <Link
                        to="/employer"
                        className="btn btn-border-brand-2 m-1"
                      >
                        Find Workers
                      </Link>
                      <Link
                        to="/institutions"
                        className="btn btn-border-brand-2 m-1"
                      >
                        Institutions
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-xl-5 col-lg-12 d-none d-xl-block col-md-6">
                  <div className="banner-imgs">
                    <div className="banner-1 shape-1">
                      <img
                        className="img-responsive"
                        alt="Restaurant Work"
                        src="assets/imgs/page/homepage5/pizza.png"
                      />
                    </div>
                    <div className="banner-2 shape-2">
                      <img
                        className="img-responsive"
                        alt="Food Delivery Jobs"
                        src="assets/imgs/page/homepage5/delivery.png"
                      />
                    </div>
                    <div className="banner-3 shape-3">
                      <img
                        className="img-responsive"
                        alt="Warehouse Jobs"
                        src="assets/imgs/page/homepage5/warehouse.png"
                      />
                    </div>
                    <div className="banner-4 shape-3">
                      <img
                        className="img-responsive"
                        alt="Nursing Jobs"
                        src="assets/imgs/page/homepage5/nurse.png"
                      />
                    </div>
                    <div className="banner-5 shape-2">
                      <img
                        className="img-responsive"
                        alt="Day Care Jobs"
                        src="assets/imgs/page/homepage5/day-care.png"
                      />
                    </div>
                    <div className="banner-6 shape-1">
                      <img
                        className="img-responsive"
                        alt="Truck Driver Jobs"
                        src="assets/imgs/page/homepage5/driver.png"
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="box-search-2">
                <div className="block-banner ml-40">
                  <h4 className="text-2xl font-bold">Search for School</h4>
                  <SchoolSearch />
                </div>
              </div> */}
            </div>
          </div>
        </section>

        <section className="section-box overflow-visible mt-50 mb-50">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                <div className="text-center">
                  <h1 className="text-6xl leading-snug font-extrabold text-[#3C65F5]">
                    <span className="count">24</span>
                    <span> m</span>
                  </h1>
                  <h5 className="text-xl font-bold">Potential Workers</h5>
                  <p className="font-sm color-text-paragraph mt-10">
                    Adult School Students and Job Center Attendees are comprised
                    of talented candidates who are often unemployed or
                    underemployed.
                  </p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                <div className="text-center">
                  <h1 className="text-6xl leading-snug font-extrabold text-[#3C65F5]">
                    <span className="count">&gt; 3000</span>
                    <span></span>
                  </h1>
                  <h5 className="text-xl font-bold">Adult Learning Schools</h5>
                  <p className="font-sm color-text-paragraph mt-10">
                    There are thousands of adult learning institutions dedicated
                    to educating and improving skills.
                  </p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                <div className="text-center">
                  <h1 className="text-6xl leading-snug font-extrabold text-[#3C65F5]">
                    <span className="count">~10m</span>
                    <span> +</span>
                  </h1>
                  <h5 className="text-xl font-bold">job openings</h5>
                  <p className="font-sm color-text-paragraph mt-10">
                    Unprecedented hiring shortfall means employers need to look
                    for workers in new places.
                  </p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                <div className="text-center">
                  <h1 className="text-6xl leading-snug font-extrabold text-[#3C65F5]">
                    <span className="count">80%</span>
                  </h1>
                  <h5 className="text-xl font-bold">of adults</h5>
                  <p className="font-sm color-text-paragraph mt-10">
                    who say that getting a better job is why they pursue post
                    secondary education.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <section className="section-box mt-50">
          <div className="section-box wow animate__animated animate__fadeIn">
            <div className="container">
              <div className="text-start">
                <h2 className="text-3xl font-bold">Latest Jobs Post</h2>
                <p className="font-lg color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                  Explore the different types of available jobs to apply
                  <br className="d-none d-lg-block" />
                  discover which is right for you.
                </p>
              </div>
              <div className="mt-50">
                <CategoryTab2 />
              </div>
            </div>
          </div>
        </section> */}

        {/* <section className="section-box mt-50 mb-30 bg-border-3 pt-100 pb-100">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <img
                  className="bdrd-10"
                  src="assets/imgs/page/homepage5/img-profile.png"
                  alt="jobBox"
                />
              </div>
              <div className="col-lg-6">
                <div className="pl-30">
                  <h5 className="text-2xl font-bold text-[#S3C65F5] mb-15 mt-15">
                    Create Profile
                  </h5>
                  <h2 className="text-4xl  font-bold mt-0 mb-15">
                    Do you attend an Adult Learning or Job Center?
                  </h2>
                  <p className="font-lg color-text-paragraph-2">
                    Create your profile here!
                  </p>
                  <div className="mt-20">
                    <a className="btn btn-default" href="/register">
                      Create Profile
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        <section className="section-box mt-70 mb-40">
          <div className="container">
            <div className="text-start">
              <h2 className="text-4xl font-bold">
                Our platform helps students, institutions and employers through
                our exclusive focus on adult schools and their students.{" "}
              </h2>
              <p className="font-lg mt-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                Learn how we help students, institutions, and employers through
                our extensive focus on adult schools and job
              </p>
            </div>
            <div className="mt-70">
              <div className="row">
                <div className="col-lg-4">
                  <div className="box-step step-1">
                    <h1 className="number-element text-6xl pt-4 font-bold">
                      1
                    </h1>
                    <h4 className="mb-20 text-2xl font-bold ">
                      Our platform for
                      <br className="d-none d-lg-block" />
                      Adult Schools allows schools to:
                    </h4>
                    <p className="font-lg color-text-paragraph-2">
                      <ul>
                        <li>Improve connections with local employers</li>
                        <li>Track and Report Student Outcomes</li>
                        <li>Host Career Fairs with advanced tracking</li>
                        <li>
                          {" "}
                          allow two-way communication with students and much
                          much more!
                        </li>
                      </ul>
                    </p>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="box-step step-2">
                    <h1 className="number-element text-6xl pt-4 font-bold">
                      2
                    </h1>
                    <h4 className="mb-20 text-2xl font-bold ">
                      Partner
                      <br className="d-none d-lg-block" />
                      with Employers
                    </h4>
                    <p className="font-lg color-text-paragraph-2">
                      We expand relationships with employers already hiring from
                      your school to make it easier for them to source students,
                      while also giving you access to other local employers in
                      the area.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="box-step">
                    <h1 className="number-element text-6xl pt-4 font-boldt">
                      3
                    </h1>
                    <h4 className="mb-20 text-2xl font-bold">
                      Leverage Student App for improved outcomes!
                    </h4>
                    <p className="font-lg color-text-paragraph-2">
                      Our exclusive Mobile App for adult education students
                      makes it simple for them to browse for jobs and connect
                      with employers. At the same time, powerful built in
                      tracking and metrics allow us to give you the data you
                      need to act, report on and succeed!
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-50 text-center">
              <a className="btn btn-default" href="/register">
                Get Started
              </a>
            </div>
          </div>
        </section>

        {/* <section className="section-box mt-70">
          <div className="container">
            <div className="text-start">
              <h2 className="text-3xl font-extrabold">
                Featured Students Looking for Work
              </h2>
              <p className="font-lg pt-2 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                Every student site will have a revolving list of top candidates
                to feature for schools.
              </p>
            </div>

            <div className="mt-50 card-bg-white">
              <div className="row">
                <div className="col-xl-3 col-lg-4 col-md-6">
                  <div className="card-grid-2 hover-up">
                    <div className="card-grid-2-image-left">
                      <div className="card-grid-2-image-rd online">
                        <Link to="candidate-details">
                          <figure>
                            <img
                              alt="jobBox"
                              src="assets/imgs/page/candidates/user1.png"
                            />
                          </figure>
                        </Link>
                      </div>
                      <div className="text-lg font-bold pt-10">
                        <Link to="candidate-details">
                          <h5>ESL Student</h5>
                        </Link>
                      </div>
                    </div>
                    <div className="card-block-info">
                      <p className="font-xs color-text-paragraph-2">
                        |Hello! My name is Jose and I am an ESL student. I'm
                        looking for retail or wholesale jobs in the Los Angeles
                        area.
                      </p>
                      <div className="card-2-bottom card-2-bottom-candidate mt-30">
                        <div className="text-start">
                          <Link
                            to="/jobs-grid"
                            className="btn btn-tags-sm mb-10 mr-5"
                          >
                            ESL
                          </Link>
                          <Link
                            to="/jobs-grid"
                            className="btn btn-tags-sm mb-10 mr-5"
                          >
                            English/Spanish
                          </Link>
                          <Link
                            to="/jobs-grid"
                            className="btn btn-tags-sm mb-10 mr-5"
                          >
                            Top Student
                          </Link>
                          <Link
                            to="/jobs-grid"
                            className="btn btn-tags-sm mb-10 mr-5"
                          >
                            Wholesale
                          </Link>
                          <Link
                            to="/jobs-grid"
                            className="btn btn-tags-sm mb-10 mr-5"
                          >
                            Retail
                          </Link>
                          <Link
                            to="/jobs-grid"
                            className="btn btn-tags-sm mb-10 mr-5"
                          >
                            Sales experience
                          </Link>
                        </div>
                      </div>
                      <div className="employers-info align-items-center justify-content-center mt-15">
                        <div className="row">
                          <div className="col-12">
                            <span className="font-sm color-text-mutted ">
                              <i className="fi-rr-marker mr-5 ml-0" />
                              Los Angeles, CA
                            </span>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6">
                  <div className="card-grid-2 hover-up">
                    <div className="card-grid-2-image-left">
                      <div className="card-grid-2-image-rd online">
                        <Link to="candidate-details">
                          <figure>
                            <img
                              alt="jobBox"
                              src="assets/imgs/page/candidates/user2.png"
                            />
                          </figure>
                        </Link>
                      </div>
                      <div className="card-profile text-lg font-bold  pt-10">
                        <Link to="candidate-details">
                          <h5>CNA Student</h5>
                        </Link>
                      </div>
                    </div>
                    <div className="card-block-info">
                      <p className="font-xs color-text-paragraph-2">
                        | Hi! I'm Cody. I'm looking for caretaker or nursing
                        assistant roles that will prepare me to become a CNA
                        someday
                      </p>
                      <div className="card-2-bottom card-2-bottom-candidate mt-30">
                        <div className="text-start">
                          <Link
                            to="/jobs-grid"
                            className="btn btn-tags-sm mb-10 mr-5"
                          >
                            CNA experience
                          </Link>
                          <Link
                            to="/jobs-grid"
                            className="btn btn-tags-sm mb-10 mr-5"
                          >
                            English
                          </Link>
                          <Link
                            to="/jobs-grid"
                            className="btn btn-tags-sm mb-10 mr-5"
                          >
                            Nursing
                          </Link>
                          <Link
                            to="/jobs-grid"
                            className="btn btn-tags-sm mb-10 mr-5"
                          >
                            Caretaking
                          </Link>
                          <Link
                            to="/jobs-grid"
                            className="btn btn-tags-sm mb-10 mr-5"
                          >
                            Digital
                          </Link>
                        </div>
                      </div>
                      <div className="employers-info align-items-center justify-content-center mt-15">
                        <div className="row">
                          <div className="col-12">
                            <span className="font-sm color-text-mutted ">
                              <i className="fi-rr-marker mr-5 ml-0" />
                              Los Angeles, CA
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-3 col-lg-4 col-md-6">
                  <div className="card-grid-2 hover-up">
                    <div className="card-grid-2-image-left">
                      <div className="card-grid-2-image-rd online">
                        <Link to="candidate-details">
                          <figure>
                            <img
                              alt="jobBox"
                              src="assets/imgs/page/candidates/user3.png"
                            />
                          </figure>
                        </Link>
                      </div>
                      <div className="card-profile text-lg font-bold  pt-10">
                        <Link to="candidate-details">
                          <h5>Bookkeeping Certificate Student</h5>
                        </Link>
                      </div>
                    </div>
                    <div className="card-block-info">
                      <p className="font-xs color-text-paragraph-2">
                        | Hi, I'm Rosa. I'm bilingual and fluent in both English
                        and Spanish. I am looking for entry level Accounting
                        positions
                      </p>
                      <div className="card-2-bottom card-2-bottom-candidate mt-30">
                        <div className="text-start">
                          <Link
                            to="/jobs-grid"
                            className="btn btn-tags-sm mb-10 mr-5"
                          >
                            Accounting
                          </Link>
                          <Link
                            to="/jobs-grid"
                            className="btn btn-tags-sm mb-10 mr-5"
                          >
                            Bookkeeping
                          </Link>
                          <Link
                            to="/jobs-grid"
                            className="btn btn-tags-sm mb-10 mr-5"
                          >
                            Certificate
                          </Link>
                          <Link
                            to="/jobs-grid"
                            className="btn btn-tags-sm mb-10 mr-5"
                          >
                            English/Spanish
                          </Link>
                          <Link
                            to="/jobs-grid"
                            className="btn btn-tags-sm mb-10 mr-5"
                          >
                            Top Student
                          </Link>
                        </div>
                      </div>
                      <div className="employers-info align-items-center justify-content-center mt-15">
                        <div className="row">
                          <div className="col-12">
                            <span className="font-sm color-text-mutted ">
                              <i className="fi-rr-marker mr-5 ml-0" />
                              Los Angeles, CA
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6">
                  <div className="card-grid-2 hover-up">
                    <div className="card-grid-2-image-left">
                      <div className="card-grid-2-image-rd online">
                        <Link to="candidate-details">
                          <figure>
                            <img
                              alt="jobBox"
                              src="assets/imgs/page/candidates/user4.png"
                            />
                          </figure>
                        </Link>
                      </div>
                      <div className="card-profile text-lg font-bold  pt-10">
                        <Link to="candidate-details">
                          <h5>ESL Student</h5>
                        </Link>
                      </div>
                    </div>
                    <div className="card-block-info">
                      <p className="font-xs color-text-paragraph-2">
                        | Hi! I'm Michelle - I've recently got my GED and am
                        looking for jobs in the LA area in either Retail or
                        Wholesale, preferably at night as I am going to school
                        in the morning.
                      </p>
                      <div className="card-2-bottom card-2-bottom-candidate mt-30">
                        <div className="text-start">
                          <Link
                            to="/jobs-grid"
                            className="btn btn-tags-sm mb-10 mr-5"
                          >
                            Top Student
                          </Link>
                          <Link
                            to="/jobs-grid"
                            className="btn btn-tags-sm mb-10 mr-5"
                          >
                            Retail
                          </Link>
                          <Link
                            to="/jobs-grid"
                            className="btn btn-tags-sm mb-10 mr-5"
                          >
                            WholeSale
                          </Link>
                          <Link
                            to="/jobs-grid"
                            className="btn btn-tags-sm mb-10 mr-5"
                          >
                            English
                          </Link>
                        </div>
                      </div>
                      <div className="employers-info align-items-center justify-content-center mt-15">
                        <div className="row">
                          <div className="col-12">
                            <span className="font-sm color-text-mutted ">
                              <i className="fi-rr-marker mr-5 ml-0" />
                              Los Angeles, CA
                            </span>
                          </div>
                          <div className="col-6">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-40 mb-60">
                <div className="col-lg-12">
                </div>
              </div>
            </div>
          </div>
        </section> */}

        <section className="section-box mt-50 mb-50">
          <div className="container">
            <div className="text-start">
              <h2 className="section-title mb-10 text-4xl font-bold wow animate__animated animate__fadeInUp">
                News and Blog
              </h2>
              <p className="font-lg pt-3 color-text-paragraph-2 wow animate__animated animate__fadeInUp">
                Get the latest news, updates and tips - we post new content from
                ourselves and our partners on a regular basis.
              </p>
            </div>
          </div>
          <div className="container">
            <div className="mt-50">
              <div className="box-swiper style-nav-top ">
                <BlogSlider />
              </div>
              {/* <div className="text-center">
                                    <Link className="btn btn-brand-1 btn-icon-load mt--30 hover-up" to="/blog-grid">
                                        Load More Posts
                                    </Link>
                                </div> */}
            </div>
          </div>
        </section>

        <section className="section-box mt-50 mb-20">
          <div className="container">
            <div className="box-newsletter box-newsletter-3">
              <div className="row">
                <div className="col-xl-12 text-center">
                  <div className="d-inline-block">
                    <h2 className="color-white text-4xl font-bold mt-30">
                      Join now to get more information or register.
                    </h2>
                    <div className="box-form-newsletter mt-30">
                      <form className="form-newsletter">
                        <input
                          className="input-newsletter"
                          type="text"
                          placeholder="Enter your email here"
                        />
                        <button className="btn btn-default font-heading icon-send-letter">
                          Join
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Home;
