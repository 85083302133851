// employersSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { db } from "../../services/firebase";
import { v4 as uuidv4 } from "uuid";

const
  setEmployerDoc = (employerDoc) => {
  let employer = undefined;
  if (employerDoc.exists()) {
    employer = employerDoc.data();
  }

  return employer;
}

export const fetchEmployers = createAsyncThunk("employers/fetchEmployers", async () => {
  const querySnapshot = await getDocs(collection(db, "employers"));
  let employers = querySnapshot.docs.map((doc) => setEmployerDoc(doc));
  return employers;
});

export const fetchEmployer = createAsyncThunk("employers/fetchEmployer", async (employerId) => {
  const employerDoc = await getDoc(doc(db, "employers", employerId?.trim()));
  return setEmployerDoc(employerDoc);
});

export const fetchEmployerByEmail = createAsyncThunk(
  "employers/fetchEmployerByEmail",
  async (email) => {
    const q = query(collection(db, "employers"), where("email", "==", email));

    const querySnapshot = await getDocs(q);
    let employers = querySnapshot.docs.map((doc) => setEmployerDoc(doc));
    return employers[0];
  }
);

export const fetchEmployersByEmail = createAsyncThunk(
  "employers/fetchEmployersByEmail",
  async (email) => {
    const q = query(collection(db, "employers"), where("email", "==", email));

    const querySnapshot = await getDocs(q);
    let employers = querySnapshot.docs.map((doc) => setEmployerDoc(doc));

    // Sort employers by dateCreated (assuming dateCreated is a timestamp)
    employers.sort((a, b) => a.dateCreated - b.dateCreated);

    return employers;
  }
);


export const setEmployer = createAsyncThunk("employers/setEmployer", async (employerData) => {
  if (!employerData.id) {
    employerData.id = uuidv4();
    employerData.dateCreated = new Date();
  }
  employerData.dateUpdated = new Date();
  const employersRef = collection(db, "employers");

  return await setDoc(doc(employersRef, employerData.id), employerData, { merge: true }).then(u => { 
    return {
      id: employerData.id,
      email: employerData.email,
    };
  });
});

const employersSlice = createSlice({
  name: "employers",
  initialState: {
    employer: undefined,
    employers: [],
    isLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchEmployers.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchEmployers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.employers = action.payload;
      })
      .addCase(fetchEmployers.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(fetchEmployer.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchEmployer.fulfilled, (state, action) => {
        state.isLoading = false;
        state.employer = action.payload;
      })
      .addCase(fetchEmployer.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(fetchEmployerByEmail.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchEmployerByEmail.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(fetchEmployerByEmail.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(fetchEmployersByEmail.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchEmployersByEmail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.employers = action.payload;
        // Select the first employer
        state.employer = action.payload.length > 0 ? action.payload[0] : undefined;
      })
      .addCase(fetchEmployersByEmail.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(setEmployer.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(setEmployer.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(setEmployer.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export default employersSlice.reducer;
export const { actions: employersActions } = employersSlice;


