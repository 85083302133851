import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import { StudentHome } from "./pages/StudentHome";
import { Provider } from "react-redux";
import store from "./store/store";
// import Jobs from "./pages/Jobs";
// import Auth from "./pages/Auth";
// import PasswordReset from "./pages/PasswordReset";
// import Profile from "./pages/Profile";
// import CandidateGrid from "./pages/CandidateGrid";
// import Register from "./pages/Register";
import { authenticate } from "./services/firebase";
import { onAuthStateChanged } from "firebase/auth";
import { useEffect } from "react";
import Layout from "./components/Layout/Layout";
import algoliasearch from "algoliasearch/lite";
import { InstantSearch } from "react-instantsearch-hooks-web";
// import PartnerJobs from "./pages/PartnerJobs";
// import PostJob from "./pages/PostJob";
import PrivacyPolicy from "./pages/legal/privacy-policy";
import CookiePolicy from "./pages/legal/cookie-policy";
import TOS from "./pages/legal/terms-and-conditions";
import ContactForm from "./pages/ContactUs";
// import EmployerSignup from "./pages/EmployerSignup";
// import PartnerDashboard from "./pages/PartnerDashboard";
// import EmployerDashboard from "./pages/EmployerDashboard";
// import EmployerProfile from "./pages/EmployerProfile";
import About from "./pages/About";
// import SchoolNotRegister from "./pages/SchoolNotRegister";
// import EventDashbaord from "./pages/EventDashbaord";
// import CreateNewEvent from "./components/event_data/CreateNewEvent";
import { EmployerHome } from "./pages/EmployerHome";

const searchClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_KEY,
  process.env.REACT_APP_ALGOLIA_SECRET
);

function App() {
  useEffect(() => {
    onAuthStateChanged(authenticate, (user) => {
      if (user) {
        localStorage.setItem("isLoggedIn", true);
      } else {
        localStorage.setItem("isLoggedIn", false);
      }
    });
  }, []);

  return (
    <InstantSearch searchClient={searchClient} indexName="job_search_index">
      <Provider store={store}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<Home />} />
              {/* <Route path="jobs" element={<Jobs />} />
              <Route path="auth" element={<Auth />} />
              <Route path="password" element={<PasswordReset />} />
              <Route path="job-candidates" element={<CandidateGrid />} />
              <Route path="register" element={<Register />} />*/}
              <Route path="about" element={<About />} />
            </Route>
            <Route path="student" element={<Layout />}>
              <Route index element={<StudentHome />} />
              {/* <Route path="auth" element={<Auth />} />
              <Route path="profile" element={<Profile />} />
              <Route path="dashboard" element={<Profile />} />
              <Route path="jobs" element={<PartnerJobs />} />
              <Route path="school-not-found" element={<SchoolNotRegister />} /> */}
            </Route>
            <Route path="employer" element={<Layout />}>
              <Route index element={<EmployerHome />} />
              {/* <Route path="auth" element={<Auth />} />
              <Route path="dashboard" element={<EmployerDashboard />} />
              <Route path="register" element={<EmployerSignup />} />
              <Route path="post-job" element={<PostJob />} />
              <Route path="employer-profile" element={<EmployerProfile />} /> */}
            </Route>
            <Route path="institutions" element={<Layout />}>
              <Route index element={<ContactForm />} />
              {/* <Route path="register" element={<ContactForm />} />
              <Route path="auth" element={<Auth />} />
              <Route path="dashboard" element={<PartnerDashboard />} />
              <Route path="event" element={<EventDashbaord />} />
              <Route path="event-create" element={<CreateNewEvent />} /> */}
            </Route>
            <Route path="legal" element={<Layout />}>
              <Route path="privacy-policy" element={<PrivacyPolicy />} />
              <Route path="cookie-policy" element={<CookiePolicy />} />
              <Route path="terms-of-service" element={<TOS />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </Provider>
    </InstantSearch>
  );
}

export default App;
