// userApplicationsSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { db } from "../../services/firebase";
import { v4 as uuidv4 } from "uuid";

const setUserApplicationDoc = (userApplicationDoc) => {
  let userApplication = undefined;

  if (userApplicationDoc.exists()) {
    userApplication = userApplicationDoc.data();
  }
  return userApplication;
};

export const fetchUserApplications = createAsyncThunk(
  "userApplications/fetchUserApplications",
  async (ids = undefined) => {
    let userApplications;
    try {
      let tempcollection;
      if (ids && ids.length > 0) {
        tempcollection = query(
          collection(db, "user-applications"),
          where("id", "in", ids)
        );
      } else {
        tempcollection = collection(db, "user-applications");
      }

      const querySnapshot = await getDocs(tempcollection);

      userApplications = querySnapshot.docs.map((doc) =>
        setUserApplicationDoc(doc)
      );
    } catch (e) {
      console.error("*** ", e);
    }
    return userApplications;
  }
);
export const fetchUserApplicationsByUserId = createAsyncThunk(
  "userApplications/fetchUserApplicationsByUserId",
  async (userIds) => {
    try {
      console.log("inside userIds", userIds);
      const chunkSize = 30;
      const userApplications = [];

      for (let i = 0; i < userIds.length; i += chunkSize) {
        const chunk = userIds.slice(i, i + chunkSize);
        console.log("chunck " , chunk)
        let tempcollection = query(
          collection(db, "user-applications"),
          where("applicantId", "in", chunk)
        );
        console.log("jobs where applied...", tempcollection);
        const querySnapshot = await getDocs(tempcollection);
        console.log("jobs where applied...", querySnapshot);
        const chunkUserApplications = querySnapshot.docs.map((doc) =>
          setUserApplicationDoc(doc)
        );
        userApplications.push(...chunkUserApplications);
      }

      console.log("querySnapshot user app", userApplications);
      return userApplications;
    } catch (error) {
      console.log("Error occurred", error);
      throw error; // You may want to re-throw the error to handle it in the caller.
    }
  }
);


export const fetchUserApplicationsByEmployerId = createAsyncThunk(
  "userApplications/fetchUserApplicationsByEmployerId",
  async (employerId) => {
    let tempcollection = query(
      collection(db, "user-applications"),
      where("job.employerId", "==", employerId.trim())
    );
    const querySnapshot = await getDocs(tempcollection);

    let userApplications = querySnapshot.docs.map((doc) =>
      setUserApplicationDoc(doc)
    );
    return userApplications;
  }
);

export const fetchUserApplicationsByEmployerIdForUser = createAsyncThunk(
  "userApplications/fetchUserApplicationsByEmployerIdForUser",
  async ({ employer, email }) => {
    try {
      let tempcollection;
      if (employer.isAdmin) {
        tempcollection = query(
          collection(db, "user-applications"),
          where("applicantEmail", "==", email)
        );
      } else {
        tempcollection = query(
          collection(db, "user-applications"),
          where("applicantEmail", "==", email),
          where("employerId", "==", employer.id)
        );
      }

      const querySnapshot = await getDocs(tempcollection);
      console.log("jobs where applied...", querySnapshot);

      let userApplications = querySnapshot.docs.map((doc) =>
        setUserApplicationDoc(doc)
      );
      console.log("querySnapshot user app", querySnapshot);
      return userApplications;
    } catch (e) {
      console.error("*** e", e);
    }
  }
);

export const fetchUserApplication = createAsyncThunk(
  "userApplications/fetchUserApplication",
  async (userApplicationId) => {
    const userApplicationDoc = await getDoc(
      doc(db, "user-applications", userApplicationId)
    );
    return setUserApplicationDoc(userApplicationDoc);
  }
);

export const fetchUserApplicationsByEmployerEmail = createAsyncThunk(
  "userApplications/fetUserApplicationsByEmployerEmail",
  async (email) => {
    const q = query(
      collection(db, "user-applications"),
      where("employerEmail", "==", email)
    );
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map((doc) => setUserApplicationDoc(doc));
  }
);

export const fetchUserApplicationsByApplicantEmail = createAsyncThunk(
  "userApplications/fetchUserApplicationsByApplicantEmail",
  async (email) => {
    const q = query(
      collection(db, "user-applications"),
      where("applicantEmail", "==", email.trim())
    );
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map((doc) => setUserApplicationDoc(doc));
  }
);

export const setUserApplication = createAsyncThunk(
  "userApplications/setUserApplication",
  async (userApplicationData) => {
    try {
      if (!userApplicationData.id) {
        userApplicationData.id = uuidv4();
        userApplicationData.dateCreated = new Date();
      }
      userApplicationData.dateUpdated = new Date();
      const userApplicationsRef = collection(db, "user-applications");

      return await setDoc(
        doc(userApplicationsRef, userApplicationData.id),
        userApplicationData,
        {
          merge: true,
        }
      ).then((u) => {
        return {
          id: userApplicationData.id,
        };
      });
    } catch (e) {
      console.error("adfasdf", e);
      alert(e);
    }
  }
);

const initialState = {
  userApplication: undefined,
  userApplications: [],
  userApplicationsByEmployer: [],
  userApplicationsEmployerApplicant: [],
  isLoading: false,
  error: null,
};
const userApplicationsSlice = createSlice({
  name: "userApplications",
  initialState,
  reducers: {
    resetState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserApplications.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchUserApplications.fulfilled, (state, action) => {
        try {
          state.isLoading = false;
          state.userApplications = action.payload;
        } catch (e) {
          console.log("***", e);
        }
      })
      .addCase(fetchUserApplications.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(fetchUserApplicationsByUserId.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchUserApplicationsByUserId.fulfilled, (state, action) => {
        state.isLoading = false;
        state.userApplications = action.payload;
      })
      .addCase(fetchUserApplicationsByUserId.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(fetchUserApplicationsByEmployerId.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchUserApplicationsByEmployerId.fulfilled, (state, action) => {
        state.isLoading = false;
        state.userApplications = action.payload;
      })
      .addCase(fetchUserApplicationsByEmployerId.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(fetchUserApplication.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchUserApplication.fulfilled, (state, action) => {
        state.isLoading = false;
        state.userApplication = action.payload;
      })
      .addCase(fetchUserApplication.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(fetchUserApplicationsByEmployerEmail.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        fetchUserApplicationsByEmployerEmail.fulfilled,
        (state, action) => {
          state.isLoading = false;
          state.userApplications = action.payload;
        }
      )
      .addCase(
        fetchUserApplicationsByEmployerEmail.rejected,
        (state, action) => {
          state.isLoading = false;
          state.error = action.error.message;
        }
      )
      .addCase(fetchUserApplicationsByApplicantEmail.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        fetchUserApplicationsByApplicantEmail.fulfilled,
        (state, action) => {
          state.isLoading = false;
          state.userApplications = action.payload;
        }
      )
      .addCase(
        fetchUserApplicationsByApplicantEmail.rejected,
        (state, action) => {
          state.isLoading = false;
          state.error = action.error.message;
        }
      )

      .addCase(fetchUserApplicationsByEmployerIdForUser.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        fetchUserApplicationsByEmployerIdForUser.fulfilled,
        (state, action) => {
          state.isLoading = false;
          state.userApplicationsEmployerApplicant = action.payload;
        }
      )
      .addCase(
        fetchUserApplicationsByEmployerIdForUser.rejected,
        (state, action) => {
          state.isLoading = false;
          state.error = action.error.message;
        }
      )
      .addCase(setUserApplication.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(setUserApplication.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(setUserApplication.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(userApplicationsActions.resetState, (state) => initialState);
  },
});

export default userApplicationsSlice.reducer;
export const { actions: userApplicationsActions } = userApplicationsSlice;
