import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { authActions, signOut } from "../../store/reducers/authSlice";
import { partnersActions } from "../../store/reducers/partnerSlice";
import { usersActions } from "../../store/reducers/usersSlice";
import UserRolesEnum from "../utills/enums";
import { fetchEmployers } from "../../store/reducers/employersSlice";
/* eslint-disable */
const Header = ({ handleOpen, handleRemove, openClass }) => {
  const [showStickyBar, setScroll] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useSelector((state) => state.auth);
  const partner = useSelector((state) => state.partners.partner);
  const { pathname } = location;
  const [logo, setLogo] = useState(undefined);
  const [isLoggedIn, setIsLoggedIn] = useState(undefined);
  const [LoginRole, setLoginRole] = useState(undefined);
  const dispatch = useDispatch();

  useEffect(() => {
    document.addEventListener("scroll", () => {
      const shouldShowStickyBar = window.scrollY > 100;
      if (shouldShowStickyBar !== showStickyBar) {
        setScroll(shouldShowStickyBar);
      }
    });
  });
  const loginUserRole = localStorage.getItem("Role");

  useEffect(() => {
    const isPartnerPage = pathname.startsWith("/student/jobs");
    if (partner && isPartnerPage) {
      setLogo({ logoUrl: partner.logoUrl, partnerName: partner.name });
    } else {
      setLogo(null);
    }
  }, [location, partner, dispatch, pathname]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const handleSignOut = () => {
    dispatch(signOut()).then(() => {
      dispatch(authActions.resetState());
      dispatch(partnersActions.resetState());
      dispatch(usersActions.resetState());
      setLogo(undefined);
      setIsLoggedIn(undefined);
      localStorage.setItem("isLoggedIn", false);
      localStorage.removeItem("userId");
      localStorage.removeItem("Role");
      setLoginRole(undefined);
      navigate("/");
    });
  };

  useEffect(() => {
    const storedData = localStorage.getItem("auth");
    const loginUserRole = localStorage.getItem("Role");
    setLoginRole(loginUserRole);

    if (storedData !== undefined) {
      setIsLoggedIn(JSON.parse(storedData));
    }
  }, [auth?.auth, LoginRole]);

  const handleApplications = () => {
    dispatch(fetchEmployers()).then((x) => {
      const reg = x.payload;
      console.log("***", reg);
      for (let a = 0; a < reg.length; a++) {
        console.log(
          `${reg[a].email},${reg[a].name},${reg[a].schoolName},${reg[a].phoneNumber}`
        );
      }
      console.log("end");
    });
  };

  return (
    <>
      <header
        className={
          showStickyBar ? "header sticky-bar stick" : "header sticky-bar"
        }
      >
        <div className="container">
          <div className="main-header">
            <div className="header-left">
              <div className="header-logo">
                {!logo ||
                loginUserRole === UserRolesEnum.Employer ||
                loginUserRole === UserRolesEnum.PartnerAdmin ? (
                  <Link to="/">
                    <img alt="Adult School Jobs" src="/logo.jpeg" />
                  </Link>
                ) : (
                  <img
                    alt={logo.partnerName}
                    src={logo?.logoUrl ? logo?.logoUrl : "/logo.jpeg"}
                    className="partner-logo"
                  />
                )}
              </div>
            </div>
            <div className="header-nav">
              <nav className="nav-main-menu">
                <ul className="main-menu">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/student">Students</Link>
                  </li>
                  <li>
                      <Link to="/employer">Employers</Link>
                  </li>
                  <li>
                      <Link to="/institutions">Institutions</Link>
                  </li>
                  <li>
                    <Link to="/about">About Us</Link>
                  </li>
                </ul>
              </nav>
             <div
                className={`burger-icon burger-icon-white ${
                  openClass && "burger-close"
                }`}
                onClick={() => {
                  handleOpen();
                  handleRemove();
                }}
              >
                <span className="burger-icon-top" />
                <span className="burger-icon-mid" />
                <span className="burger-icon-bottom" />
              </div>
            </div>
            <div className="header-right flex-none">
              {/* {loginUserRole === UserRolesEnum.Student && isLoggedIn?.email && (
                <Link to="/student/dashboard" className="btn">
                  Dashboard
                </Link>
              )}
              {isLoggedIn?.email ? (
                <button
                  className="btn btn-outline-dark"
                  onClick={handleSignOut}
                >
                  Sign Out
                </button>
              ) : (
                <div className="block-signin">
                  <Link
                    to="auth"
                    className="btn btn-default btn-shadow ml-40 hover-up"
                  >
                    Sign in
                  </Link>
                </div>
              )} */}
              <div className="block-signin">
                <a
                  className="text-brand-2 font-weight-bold"
                  href="https://app.adultedpro.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Sign in
                </a>

                {/* <button className="bg-blue-500" onClick={handleApplications}>
                  get records
                </button> */}
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
