// usersSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { db } from "../../services/firebase";
import { v4 as uuidv4 } from "uuid";
import { super_admin_id } from "../../components/utills/defaultdata";

const setUserDoc = (userDoc) => {
  let user = undefined;
 
  if (userDoc.exists()) {
    user = userDoc.data();
  }

  return user;
};

export const fetchUsers= createAsyncThunk(
  "users/fetchUsers",
  async (ids = undefined) => {
    let tempcollection;
    if (ids && ids.length > 0) {
      tempcollection = query(collection(db, "users"), where("id", "in", ids));
    } else {
      tempcollection = collection(db, "users");
    }

    const querySnapshot = await getDocs(tempcollection);

    let users = querySnapshot.docs.map((doc) => setUserDoc(doc));
    return users;
  }
);
export const fetchUsersByEmployerIds= createAsyncThunk(
  "users/fetchUsersByEmployerIds",
  async (ids = undefined) => {
    let tempcollection;
    if (ids && ids.length > 0) {
      tempcollection = query(collection(db, "users"), where("employerId", "in", ids));
    } else {
      tempcollection = []
    }

    const querySnapshot = await getDocs(tempcollection);

    let users = querySnapshot.docs.map((doc) => setUserDoc(doc));
    return users;
  }
);

// for employer dashboard
export const fetchallApplicantsUserByIds= createAsyncThunk(
  "users/fetchallApplicantsUsersByIds",
  async (ids = undefined) => {
    let tempcollection;
    if (ids && ids.length > 0) {
      tempcollection = query(collection(db, "users"), where("id", "in", ids));
    } else {
      tempcollection = []
    }

    const querySnapshot = await getDocs(tempcollection);


    let users = querySnapshot.docs.map((doc) => setUserDoc(doc));
    return users;
  }
);
export const fetchallhiredUserByIds= createAsyncThunk(
  "users/fetchallHiredUsersByIds",
  async (ids = undefined) => {
    let tempcollection;
    if (ids && ids.length > 0) {
      tempcollection = query(collection(db, "users"), where("id", "in", ids));
    } else {
      tempcollection = []
    }

    const querySnapshot = await getDocs(tempcollection);

    let users = querySnapshot.docs.map((doc) => setUserDoc(doc));
    return users;
  }
);
export const fetchallinterviewingUserByIds= createAsyncThunk(
  "users/fetchallInterviewingUsersByIds",
  async (ids = undefined) => {
    let tempcollection;
    if (ids && ids.length > 0) {
      tempcollection = query(collection(db, "users"), where("id", "in", ids));
    } else {
      tempcollection = []
    }

    const querySnapshot = await getDocs(tempcollection);

    let users = querySnapshot.docs.map((doc) => setUserDoc(doc));
   console.log("all interiewing users..." , users )
    return users;
  }
);

export const fetchUser = createAsyncThunk("users/fetchUser", async (userId) => {
  const userDoc = await getDoc(doc(db, "users", userId));
  return setUserDoc(userDoc);
});
export const fetchSuperAdminUser = createAsyncThunk("users/fetchSuperAdminUser", async () => {
  const userDoc = await getDoc(doc(db, "users", super_admin_id.trim()));
  return setUserDoc(userDoc);
});
export const fetchChatUser1 = createAsyncThunk("users/fetchChatUser1", async (userId) => {
  const userDoc = await getDoc(doc(db, "users", userId));
  return setUserDoc(userDoc);
});
export const fetchChatUser2 = createAsyncThunk("users/fetchChatUser2", async (userId) => {
  const userDoc = await getDoc(doc(db, "users", userId));
  return setUserDoc(userDoc);
});

export const fetch_chat_reciever_data = createAsyncThunk("users/fetchChatRecieverUser", async (userId) => {
  const userDoc = await getDoc(doc(db, "users", userId));
  return setUserDoc(userDoc);
});
export const fetchUserByPartnerId = createAsyncThunk("users/fetchUserByPartnerId", async (partnerId) => {
  try {
    const q = query(collection(db, 'users'), where('partnerId', '==', partnerId.trim()), where('isPartnerAdmin', '==', true));
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          const userDoc = querySnapshot.docs[0]; // Get the first matching document
          return setUserDoc(userDoc);
        }
    
  } catch (error) {
    console.log("error while fetching user by partner id " , error)
  }
});

export const fetchstudentsByPartnerId = createAsyncThunk("users/fetchStudentsDataByPartnerId", async (partnerId) => {
  try {
    const q = query(collection(db, 'users'), where('partnerId', '==', partnerId.trim()), where('isPartnerAdmin', '==', false));
        const querySnapshot = await getDocs(q);
        let users = querySnapshot.docs.map((doc) => setUserDoc(doc));
    return users;
    
  } catch (error) {
    console.log("error while fetching user by partner id " , error)
  }
});

export const fetchUserByEmail = createAsyncThunk(
  "users/fetchUserByEmail",
  async (email) => {
    const q = query(collection(db, "users"), where("email", "==", email));

    const querySnapshot = await getDocs(q);
    let users = querySnapshot.docs.map((doc) => setUserDoc(doc));
    return users[0];
  }
);

export const setUser = createAsyncThunk("users/setUser", async (userData) => {
  if (!userData.id) {
    userData.id = uuidv4();
  }
  const usersRef = collection(db, "users");
console.log("userData..." , userData)
  return await setDoc(doc(usersRef, userData.id), userData, {
    merge: true,
  }).then((u) => {
    return {
      id: userData.id,
      email: userData.email,
    };
  });
});

const initialState = {
  user: undefined,
  superAdminUser : undefined,
  partnerUserData: undefined,
  users: [],
  employers : [],
  allHiredUsers :[],
  allApplicantsUsers : [],
  allInterviewUsers : [],
  messagereciverdata : [],
  partnerStudentsdata : [],
  chatuser1 : [],
  chatuser2 :[],
  isLoading: false,
  error: null,

};
const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    resetState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.users = action.payload;
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      .addCase(fetchChatUser1.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchChatUser1.fulfilled, (state, action) => {
        state.isLoading = false;
        state.chatuser1 = action.payload;
      })
      .addCase(fetchChatUser1.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(fetchChatUser2.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchChatUser2.fulfilled, (state, action) => {
        state.isLoading = false;
        state.chatuser2 = action.payload;
      })
      .addCase(fetchChatUser2.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(fetch_chat_reciever_data.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetch_chat_reciever_data.fulfilled, (state, action) => {
        state.isLoading = false;
        state.messagereciverdata = action.payload;
      })
      .addCase(fetch_chat_reciever_data.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(fetchUsersByEmployerIds.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchUsersByEmployerIds.fulfilled, (state, action) => {
        state.isLoading = false;
        state.employers = action.payload;
      })
      .addCase(fetchUsersByEmployerIds.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(fetchallApplicantsUserByIds.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchallApplicantsUserByIds.fulfilled, (state, action) => {
        state.isLoading = false;
        state.allApplicantsUsers = action.payload;
      })
      .addCase(fetchallApplicantsUserByIds.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(fetchallinterviewingUserByIds.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchallinterviewingUserByIds.fulfilled, (state, action) => {
        state.isLoading = false;
        state.allInterviewUsers = action.payload;
      })
      .addCase(fetchallinterviewingUserByIds.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(fetchallhiredUserByIds.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchallhiredUserByIds.fulfilled, (state, action) => {
        state.isLoading = false;
        state.allHiredUsers = action.payload;
      })
      .addCase(fetchallhiredUserByIds.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(fetchUserByPartnerId.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchUserByPartnerId.fulfilled, (state, action) => {
        state.isLoading = false;
        state.partnerUserData = action.payload;
      })
      .addCase(fetchUserByPartnerId.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(fetchUser.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user = action.payload;
      })
      .addCase(fetchUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(fetchSuperAdminUser.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchSuperAdminUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.superAdminUser = action.payload;
      })
      .addCase(fetchSuperAdminUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(fetchUserByEmail.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchUserByEmail.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(fetchUserByEmail.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(setUser.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(setUser.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(setUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(fetchstudentsByPartnerId.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchstudentsByPartnerId.fulfilled, (state, action) => {
        state.isLoading = false;
        state.partnerStudentsdata = action.payload;
      })
      .addCase(fetchstudentsByPartnerId.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      
      
      .addCase(usersActions.resetState, (state) => initialState);
  },
});

export default usersSlice.reducer;
export const { actions: usersActions } = usersSlice;
