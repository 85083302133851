import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { db } from "../../services/firebase";
import { v4 as uuidv4 } from "uuid";
import { addDoc, collection, getDocs, limit, orderBy, query } from "firebase/firestore";


export const setEvent= createAsyncThunk("events/setEvent", 
    async (eventData, {rejectWithValue} )=>{
        try {
            if(!eventData.id){
                eventData.id = uuidv4();
                eventData.dateCreated = new Date();
            }
            eventData.dateUpdated = new Date();
            const eventRef = collection(db, "events");
            await addDoc(eventRef, eventData);
            return eventData;

        } catch (error) {
            console.log("Error Creating Event", error.message);
            return rejectWithValue(error.message);

        }

    }
);

export const getLatestEvent = createAsyncThunk("events/getLatestEvent", async (eventData, { rejectWithValue }) => {

    try {
        const eventRef = collection(db, "events");
        const eventQuery = query(eventRef, orderBy("eventDate", "asc"), limit(1));
        const querySnapshot = await getDocs(eventQuery);

        let LatestEvent;
        querySnapshot.forEach((doc) => {
            LatestEvent = doc.data();
        })
        return LatestEvent;

    } catch (error) {
        console.log("Error fetching new event!", error.message);
        rejectWithValue(error.message);
    }

}
);

const eventSlice = createSlice({
    name: "events",
    initialState: {
        event:undefined,
        events: [],
        isLoading: false,
        error: null,
    },
    reducers:  {},
    extraReducers : (builder)=>{
        builder.addCase(setEvent.pending, (state)=>{
            state.isLoading= true;
            state.error= null;
        });

        builder.addCase(setEvent.fulfilled, (state, action) => {
            state.isLoading = false;
            state.event = action.payload;
        })

        builder.addCase(setEvent.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        })

        builder.addCase(getLatestEvent.pending, (state) => {
            state.isLoading = true;
            state.error = null;
        })

        builder.addCase(getLatestEvent.fulfilled, (state, action) => {
            state.isLoading = false;
            state.event = action.payload;
        })

        builder.addCase(getLatestEvent, (state, action) => {
            state.isLoading = false;
            state.error = action.error;
        })
    }

});

export default eventSlice.reducer;
export const {actions: eventActions}= eventSlice;