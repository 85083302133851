import { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { saveContactForm } from "../store/reducers/contactSlice";
import { FaUserPlus } from "react-icons/fa";
const ContactForm = () => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    institution: "",
    message: "",
  });
  const [formErrors, setErrors] = useState({});
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const partnerSignupRef = useRef(null);
  const handleCheckboxChange = (e) => {
    setCheckboxChecked(e.target.checked);
  };

  const handleSignupButton = () => {
    console.log("signup");
    setTimeout(() => {
      partnerSignupRef.current.scrollIntoView({ behavior: "smooth" });
    }, 100);
    console.log("partnerSignupRef", partnerSignupRef);
  };

  const handleInputChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
    setSuccessMessage("");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const errors = validateForm(formData);
    if (Object.keys(errors).length !== 0) {
      setErrors(errors);
      return;
    }

    const data = {
      name: formData.name,
      email: formData.email,
      phone: formData.phone,
      institution: formData.institution,
      message: formData.message,
      contactFormLocation: "Partner Contact Form",
    };
    await dispatch(saveContactForm(data)).then((x) => {
      setSuccessMessage(
        "Submitted successfully, Our Representative will get back to you soon"
      );
      // Reset the form and errors
      setFormData({
        name: "",
        email: "",
        phone: "",
        institution: "",
        message: "",
      });
      setErrors({});
    });
  };

  function validateForm(formData) {
    const errors = {};

    if (!formData.name.trim()) {
      errors.name = "Name is required";
    } else if (!/^[a-zA-Z ]+$/.test(formData.name)) {
      errors.name = "Name should only contain alphabets";
    }

    if (!formData.phone.trim()) {
      errors.phone = "Phone number is required";
    } else if (!/^\d+$/.test(formData.phone)) {
      errors.phone = "Invalid phone number format";
    }

    if (!formData.email.trim()) {
      errors.email = "Email is required";
    } else if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(formData.email)) {
      errors.email = "Invalid email format";
    }

    if (!formData.message.trim()) {
      errors.message = "Message is required";
    }

    if (!formData.institution.trim()) {
      errors.institution = "Institution Name is required";
    }

    if (!checkboxChecked) {
      errors.check_box = "Please agree to the terms and policy";
    }

    return errors;
  }

  return (
    <section className='section-box' >
      <div className='w-full py-8 bg-cover bg-center object-cover flex flex-row justify-center items-center  ' style={{ backgroundImage: 'url("/assets/imgs/page/schools/school_bg.jpg")' }}>
        <div className="text-center flex flex-col items-center justify-center">
        <h2 className='section-title mb-10 wow animate__animated animate__fadeInUp text-4xl pb-3 font-bold text-white'>
          Why should institutions sign up with us?
        </h2>
        <p className='font-sm px-1 py-3 color-text-paragraph  mx-auto wow animate__animated animate__fadeInUp text-white   w-3/5' style={{backgroundColor: "#05264e"}}>
        1: We help improve enrollment at institutions through our partnership with <a className="text-yellow-600" href="http://hiddenworkers.org" target="_blank" rel="noreferrer"> hiddenworkers.org </a>   and ,
     
        </p>
        <p className='font-sm px-1 py-3 mt-1 color-text-paragraph mx-auto wow animate__animated animate__fadeInUp text-white  w-3/5 '  style={{backgroundColor: "#05264e"}}>
        2: Students need to see better career outcomes to attend adult learning institutions - the numbers speak for themselves.
     
        </p>
        <br></br>
        <br></br>
        <Link href='page-contact'>
          <button
            className='btn btn-apply btn-apply-big flex gap-2'
            onClick={() => handleSignupButton()}
          >
            <FaUserPlus className='btn-icon' /> Sign up
          </button>
        </Link>
        </div>
      </div>
      <section className='section-box '>
        <div className='container'>
          <div className='box-info-contact'>
            <div className='row'>
              <div className='col-lg-3 col-md-6 col-sm-12 mb-30'>
                <h1 className='text-4xl font-bold'>94%</h1>
                <p className='font-sm color-text-paragraph mb-20'>
                  Percentage of employers who say their hiring processes "at
                  least sometimes" filter out potential candidates who could
                  perform offered jobs
                  <br className='d-none d-lg-block' />
                  <br className='d-none d-lg-block' />
                  <span className='font-xs reference-text'>
                    - Hidden Worker – Employer Survey,” Accenture/HBS,
                    January-February 2020
                  </span>
                </p>
              </div>
              <div className='col-lg-3 col-md-6 col-sm-12 mb-30'>
                <h1 className='text-4xl font-bold'>80%</h1>
                <p className='font-sm color-text-paragraph mb-20'>
                  Adults who say getting a good job or advancing their career
                  has the biggest influence on deciding to pursue post-secondary
                  education.
                  <br className='d-none d-lg-block' />
                  <br className='d-none d-lg-block' />
                  <br className='d-none d-lg-block' />
                  <span className='font-xs reference-text'>
                    -2022 Strada Education Network Survey
                  </span>
                </p>
              </div>
              <div className='col-lg-3 col-md-6 col-sm-12 mb-30'>
                <h1 className='text-4xl font-bold'>39%</h1>
                <p className='font-sm color-text-paragraph mb-20'>
                  Adults not attending adult educational programs that believe
                  that post-secondary education will not provide them with
                  better work outcomes.
                  <br className='d-none d-lg-block' />
                  <br className='d-none d-lg-block' />
                  <span className='font-xs reference-text'>
                    -2022 Strada Education Network Survey
                  </span>
                </p>
              </div>
              <div className='col-lg-3 col-md-6 col-sm-12 mb-30'>
                <h1 className='text-4xl font-bold'>49%</h1>
                <p className='font-sm color-text-paragraph mb-20'>
                  Hidden workers who would pay for college/university studies
                  that improve employability.
                  <br className='d-none d-lg-block' />
                  <br className='d-none d-lg-block' />
                  <br className='d-none d-lg-block' />
                  <br className='d-none d-lg-block' />
                  <span className='font-xs reference-text'>
                    -Hidden Workers: Untapped Talent HBS/Accenture 2021
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div ref={partnerSignupRef} className='container'>
          <div className='row'>
            <div className='col-lg-8 mb-40'>
              <span className='font-md color-brand-2 mt-20 d-inline-block'>
                Contact us
              </span>
              <h2 className='mt-5 mb-10 text-3xl font-bold'>School Signup</h2>
              <p className='font-md color-text-paragraph-2'>
                We are signing up new institutions all the time. See why
                institutions are turning to us to improve student outcomes!
              </p>
              <form
                className='contact-form-style mt-30'
                id='contact-form'
                action='#'
                method='post'
              >
                <div
                  className='row wow animate__animated animate__fadeInUp'
                  data-wow-delay='.1s'
                >
                  <div className='col-lg-6 col-md-6'>
                    <div className='input-style mb-20'>
                      <input
                        className='font-sm color-text-paragraph-2'
                        name='name'
                        placeholder='Name'
                        type='text'
                        value={formData.name}
                        onChange={handleInputChange}
                        required
                      />
                      {formErrors.name && (
                        <div className='text-danger'>{formErrors.name}</div>
                      )}
                    </div>
                  </div>
                  <div className='col-lg-6 col-md-6'>
                    <div className='input-style mb-20'>
                      <input
                        className='font-sm color-text-paragraph-2'
                        name='institution'
                        placeholder='Institution Name'
                        type='text'
                        value={formData.institution}
                        onChange={handleInputChange}
                        required
                      />
                      {formErrors.institution && (
                        <div className='text-danger'>
                          {formErrors.institution}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='col-lg-6 col-md-6'>
                    <div className='input-style mb-20'>
                      <input
                        className='font-sm color-text-paragraph-2'
                        name='email'
                        placeholder='Your email'
                        type='email'
                        value={formData.email}
                        onChange={handleInputChange}
                        required
                      />
                      {formErrors.email && (
                        <div className='text-danger'>{formErrors.email}</div>
                      )}
                    </div>
                  </div>
                  <div className='col-lg-6 col-md-6'>
                    <div className='input-style mb-20'>
                      <input
                        className='font-sm color-text-paragraph-2'
                        name='phone'
                        placeholder='Phone number'
                        type='tel'
                        value={formData.phone}
                        onChange={handleInputChange}
                        required
                      />
                      {formErrors.phone && (
                        <div className='text-danger'>{formErrors.phone}</div>
                      )}
                    </div>
                  </div>
                  <div className='col-lg-12 col-md-12'>
                    <div className='textarea-style mb-30'>
                      <textarea
                        className='font-sm color-text-paragraph-2'
                        name='message'
                        placeholder='Type your message here'
                        value={formData.message}
                        onChange={handleInputChange}
                        required
                      />
                      {formErrors.message && (
                        <div className='text-danger'>{formErrors.message}</div>
                      )}
                    </div>
                    <button
                      className='submit btn btn-send-message bg-[#05264e]'
                      type='submit'
                      onClick={handleSubmit}
                    >
                      Send message
                    </button>
                    {formErrors.check_box && (
                      <div className='text-danger'>{formErrors.check_box}</div>
                    )}
                    <label className='ml-20'>
                      <input
                        className='float-start mr-5 mt-6'
                        type='checkbox'
                        checked={checkboxChecked}
                        onChange={handleCheckboxChange}
                        required
                      />{" "}
                      By clicking send message button, you agree our{" "}
                      <a href='/legal/terms-of-service'>Terms of Service</a>.
                    </label>
                    {successMessage && (
                      <div className='text-success'>{successMessage}</div>
                    )}
                    <div className='ml-20'>
                      <div className='float-start mr-5 mt-6'>
                        Already have an account? &nbsp;
                         <a
                            className="text-brand-2 font-weight-bold"
                            href="https://app.adultedpro.com/auth/school/signin"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                          Sign in
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
              <p className='form-messege' />
            </div>
            <div className='col-lg-4 text-center d-none d-lg-block'>
              <img
                src='/assets/imgs/page/contact/img.png'
                alt='joxBox'
                className='img-with-margin'
              />
            </div>
          </div>
        </div>
      </section>
    </section>
  );
};

export default ContactForm;
