const CookiePolicy = () => {

  return (
    <>
     <div class="legal-content container section-full">
  <h1>Cookie Policy</h1>
  <p>
    At AdultEdPro.com, we respect the privacy of our visitors and are
    committed to being transparent about the use of cookies on our website. This
    Cookie Policy explains how we use cookies and similar technologies on our
    website, and your choices regarding these technologies.
  </p>
  <h2>What are cookies?</h2>
  <p>
    Cookies are small text files that are stored on your device when you visit a
    website. They are widely used to make websites work more efficiently and to
    provide website owners with information about how visitors use their
    website.
  </p>
  <h2>How we use cookies?</h2>
  <p>We use cookies on our website for the following purposes:</p>

  <ul>
    <li>
      Essential cookies: These cookies are necessary for our website to function
      properly. They allow you to navigate our website, use its features, and
      access secure areas.
    </li>
    <li>
      Analytics cookies: These cookies collect information about how visitors
      use our website, such as which pages they visit most often and if they
      receive error messages. This information is used to improve our website's
      performance and usability.
    </li>
    <li>
      Advertising cookies: These cookies are used to deliver relevant
      advertisements to you based on your browsing history and interests. They
      also help us measure the effectiveness of our advertising campaigns.
    </li>
    <li>
      Social media cookies: These cookies are used to enable you to share
      content on social media platforms, such as Facebook and Twitter.
    </li>
  </ul>

  <h2>Your choices regarding cookies</h2>
  <p>
    You can control the use of cookies on our website through your browser
    settings. Most browsers allow you to block or delete cookies. However,
    please note that blocking or deleting cookies may impact your experience of
    our website.
  </p>
  <p>
    We also use a cookie consent tool to obtain your consent for the use of
    non-essential cookies. You may choose to accept or reject these cookies.
  </p>
  <h2>Changes to this Cookie Policy</h2>
  <p>
    We may update this Cookie Policy from time to time. If we make any material
    changes, we will notify you by posting the updated policy on our website.
  </p>
  <h2>Contact us</h2>
  <p>
    If you have any questions about this Cookie Policy or our use of cookies,
    please contact us at privacy@adultedpro.com.
  </p>
</div>
    </>
  );
};

export default CookiePolicy;


