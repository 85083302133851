import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { sendContactForm } from "../../services/email";

export const saveContactForm = createAsyncThunk(
    "ContactForm/saveContactForm",
    async (formData, thunkAPI) => {
      try {
        sendContactForm(formData);
      } catch (error) {
        console.log('error', error);
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  );
  

  const contactFormSlice = createSlice({
    name: "contactForm",
    initialState: {
      formData: {
        name: "",
        email: "",
        phone: "",
        institution: "",
        message: ""
      },
      isLoading: false,
      error: null,
      contactFormResponse: null,
    },
    reducers: {
      setFormData: (state, action) => {
        state.formData = action.payload;
      },
      resetForm: (state) => {
        state.formData = {
          name: "",
          email: "",
          phone: "",
          institution: "",
          message: ""
        };
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(saveContactForm.pending, (state) => {
          state.isLoading = true;
          state.error = null;
          state.contactFormResponse = null;
        })
        .addCase(saveContactForm.fulfilled, (state, action) => {
          state.isLoading = false;
          state.contactFormResponse = action.payload;
          state.error = null;
        })
        .addCase(saveContactForm.rejected, (state, action) => {
          state.isLoading = false;
          state.contactFormResponse = null;
          state.error = action.error.message;
        });
    },
  });
  
  export const { setFormData, resetForm } = contactFormSlice.actions;
  export default contactFormSlice.reducer;
  
  
  
  
  